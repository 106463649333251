<div>
  <form [formGroup]="destinationFormGroup">
    <mat-form-field (click)="departureDate.open()" color="accent" appearance="fill">
      <mat-label>
        {{ 'USER.REVIEWED_REQUESTS.START_DATE' | translate }}
      </mat-label>
      <input
        matInput
        [matDatepicker]="departureDate"
        (dateInput)="getStartDate($event)"
        required
        formControlName="departureDate"
      />
      <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
      <mat-datepicker #departureDate color="primary"></mat-datepicker>
    </mat-form-field>
    <mat-form-field (click)="returnDate.open()" color="accent" appearance="fill">
      <mat-label>
        {{ 'USER.REVIEWED_REQUESTS.END_DATE' | translate }}
      </mat-label>
      <input
        matInput
        [min]="minDate"
        [matDatepicker]="returnDate"
        required
        formControlName="returnDate"
      />
      <mat-datepicker-toggle matSuffix [for]="returnDate"></mat-datepicker-toggle>
      <mat-datepicker #returnDate color="primary"></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>
        {{ 'USER.TRAVEL_REQUESTS.FROM_CITY' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="fromCity" required />
      <mat-error *ngIf="destinationFormGroup.controls.fromCity.invalid">{{
        getErrorMessage()
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>
        {{ 'USER.TRAVEL_REQUESTS.FROM_COUNTRY' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="fromCountry" required />
      <mat-error *ngIf="destinationFormGroup.controls.fromCountry.invalid">{{
        getErrorMessage()
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>
        {{ 'USER.TRAVEL_REQUESTS.TO_CITY' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="toCity" required />
      <mat-error *ngIf="destinationFormGroup.controls.toCity.invalid">{{
        getErrorMessage()
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>
        {{ 'USER.TRAVEL_REQUESTS.TO_COUNTRY' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="toCountry" required />
      <mat-error *ngIf="destinationFormGroup.controls.toCountry.invalid">{{
        getErrorMessage()
      }}</mat-error>
    </mat-form-field>
    <mat-slide-toggle
      (change)="emitRoundTripChange($event)"
      *ngIf="length === 1"
      color="primary"
      [disabled]="mode === componentModeEnum.VIEW && !isAdmin"
      [checked]="isRoundTrip"
      >Round trip</mat-slide-toggle
    >
    <mat-icon *ngIf="length !== 1" class="delete-icon" (click)="deleteRow()">cancel</mat-icon>
  </form>
</div>
