import { IRequest } from '../../models/request.model';
import { VacationTypeEnum } from '../../enums/vacation-type.enum';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Injectable } from '@angular/core';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class RequestsHelperService {
  private requestPDFExport: IRequest;

  public exportPdf(request: IRequest): void {
    this.requestPDFExport = request;
    const requester =
      this.requestPDFExport.requester.firstName + ' ' + this.requestPDFExport.requester.lastName;
    const position = this.requestPDFExport.requester.contractPosition;
    const vacationPeriodInDays = this.requestPDFExport.days;
    const requestDate = new Date(this.requestPDFExport.createdOn);
    const fromDate = new Date(this.requestPDFExport.fromDate).toLocaleDateString('bg-BG');
    const toDate = new Date(this.requestPDFExport.toDate).toLocaleDateString('bg-BG');
    const formatRequestDate = requestDate.toLocaleDateString('bg-BG');
    const dynamicText = vacationPeriodInDays > 1 ? ' работни дни' : ' работен ден';
    const documentDefinition = {
      content: [
        {
          text: 'До Управителя на Дриймикс ООД',
          alignment: 'right',
          margin: [350, 40, 50, 0],
        },
        {
          text: 'ЗАЯВЛЕНИЕ',
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 60, 0, 60],
        },
        {
          text: 'От: ' + requester + ' на длъжност ' + position,
          alignment: 'center',
          margin: [100, 0, 100, 40],
        },
        {
          text: [
            {
              text:
                '\u200B\t\tМоля да ми бъде разрешено ' + this.getVacationType() + ' в размер на ',
            },
            {
              text: vacationPeriodInDays,
              bold: true,
            },
            {
              text: dynamicText + ', за периода:',
            },
          ],
          margin: [40, 0, 40, 0],
        },
        {
          text: 'от: ' + fromDate + '     до: ' + toDate,
          margin: [40, 30, 40, 0],
        },
        {
          text: 'Надявам се заявлението ми да бъде удовлетворено.',
          margin: [40, 30, 40, 0],
        },
        {
          columns: [
            [
              {
                text: 'Дата: ' + formatRequestDate,
                alignment: 'left',
              },
            ],
            [
              {
                text: requester + ': .....................',
                alignment: 'right',
              },
              {
                text: '/ подпис /',
                alignment: 'right',
                margin: [0, 2, 0, 0],
              },
            ],
          ],
          margin: [40, 70, 40, 0],
        },
        {
          text: 'Заявеният платен годишен отпуск е разрешен: .....................',
          alignment: 'right',
          margin: [40, 50, 40, 0],
        },
        {
          text: '/ Управител, подпис /',
          alignment: 'right',
          margin: [40, 2, 40, 0],
        },
        {
          text: 'Дата: ' + formatRequestDate,
          alignment: 'right',
          margin: [40, 2, 40, 0],
        },
        {
          text: 'Запознат съм с решението на Работодателя: .....................',
          alignment: 'right',
          margin: [0, 50, 40, 0],
        },
        {
          text: '/ ' + requester + ' / подпис /',
          alignment: 'right',
          margin: [0, 2, 40, 0],
        },
        {
          text: 'Дата: ' + formatRequestDate,
          alignment: 'right',
          margin: [40, 2, 40, 0],
        },
      ],
      defaultStyle: {
        fontSize: 13,
      },
    };

    pdfMake.createPdf(documentDefinition).open();
  }

  private getVacationType(): string {
    switch (this.requestPDFExport.vacationType) {
      case VacationTypeEnum.VACATION:
        return (
          'ползването на основен платен годишен отпуск на основание чл. 155, ал. 1, от КТ от полагащия ми се за ' +
          new Date(this.requestPDFExport.createdOn).getFullYear() +
          ' г.'
        );
      case VacationTypeEnum.LEAVE_OF_ABSENCE:
        return 'ползването на неплатен отпуск по чл. 160, ал. 1, от КТ';
      case VacationTypeEnum.OTHER_PAID_LEAVE:
        return 'ползването на отпуск за изпълнение на граждански, обществени и други задължения съгласно чл. 157, ал. 1, от КТ';
      case VacationTypeEnum.HOME_OFFICE:
        return 'извършването на работа дистанционно';
      default:
        return '';
    }
  }
}
