import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'secure',
})
export class SecurePipe implements PipeTransform {
  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  transform = (url): Observable<SafeUrl> => {
    return this.httpClient
      .get(url, {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
        },
      })
      .pipe(
        map((val) => {
          return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(val));
        }),
      );
  };
}
