import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { TranslateService } from '@ngx-translate/core';
import { DIALOG_BUTTONS } from '../../constants';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.scss'],
})
export class ImageCropDialogComponent {
  public transform: ImageTransform = {};
  public croppedImage: string = '';
  readonly buttons = DIALOG_BUTTONS;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      imageChangedEvent: string;
      sharedButtonClass: string;
      sharedButtonText: string;
    },
    public translate: TranslateService,
  ) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en/) ? browserLang : 'en');
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  public getCroppedImage(): string {
    return this.croppedImage;
  }
}
