import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-multi-select-field',
  templateUrl: './multi-select-field.component.html',
  styleUrls: ['./multi-select-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectFieldComponent),
      multi: true,
    },
  ],
})
export class MultiSelectFieldComponent<T> implements ControlValueAccessor {
  private _all: T[];
  private _selected: T[];

  control = new FormControl('');
  filtered$: Observable<T[]>;
  onChange = (_: T[]) => {};
  onTouched = () => {};
  separatorKeysCodes: number[] = [ENTER, COMMA];

  get selected(): T[] {
    return this._selected || [];
  }
  set selected(value: T[]) {
    this._selected = value;
    this.onChange(value);
    this.onTouched();
  }

  @Input()
  get all(): T[] {
    return this._all;
  }
  set all(value: T[]) {
    this._all = value;
    this.initFiltered();
  }

  @Input() label: string;
  @Input() nameProperty: string = 'name';
  @Input() placeholder: string;
  @Input() optionsToExclude: T[];

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  constructor(public translate: TranslateService) {}

  clearInputValue() {
    this.input.nativeElement.value = '';
  }

  isInList(item: T): boolean {
    const isAlreadySelected = this.selected
      ? this.selected.some((e) => e['id'] === item['id'])
      : false;
    const isOptionToExclude = this.optionsToExclude
      ? this.optionsToExclude.some((e) => e['id'] === item['id'])
      : false;

    return isAlreadySelected || isOptionToExclude;
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    this.selected = [...this.selected, event.option.value];
    this.clearInputValue();
    this.control.setValue(null);
  }

  remove(item: T) {
    const index = this.selected.indexOf(item);
    if (index >= 0) {
      this.selected.splice(index, 1);
    }
  }

  // ControlValueAccessor methods
  registerOnChange(fn: (_: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(value: T[]) {
    this._selected = value;
  }
  // --

  private filterItems(value: T | string): T[] {
    let filterValue: string;

    if (typeof value === 'string') {
      filterValue = value.toLowerCase().trim();
    } else {
      filterValue = value[this.nameProperty].toLowerCase().trim();
    }

    return this.all
      ? this.all.filter((item) =>
          item[this.nameProperty].toLowerCase().trim().includes(filterValue),
        )
      : [];
  }

  private initFiltered() {
    this.filtered$ = this.control.valueChanges.pipe(
      startWith(''),
      map((value: T | string | null) => (value ? this.filterItems(value) : this.all?.slice())),
    );
  }
}
