import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { ComponentModeEnum } from 'src/app/shared/enums/component-mode.enum';
import { DestinationStatus } from 'src/app/shared/enums/destination-status.enum';

@Component({
  selector: 'app-add-destination-form',
  templateUrl: './add-destination-form.component.html',
  styleUrls: ['./add-destination-form.component.scss'],
})
export class AddDestinationFormComponent implements OnChanges {
  _mode: string;
  @Input() set mode(mode: string) {
    this._mode = mode;
  }

  get mode(): string {
    return this._mode;
  }
  componentModeEnum = ComponentModeEnum;
  @Input() destinationFormGroup: FormGroup;
  @Input() length: number;
  @Input() isRoundTrip: boolean;
  @Input() isAdmin: boolean;
  @Output() roundTrip: EventEmitter<any> = new EventEmitter();
  @Output() deleteDestination: EventEmitter<any> = new EventEmitter();
  travelForm: FormGroup;
  minDate: Date;
  returnDate: Date;

  constructor(public translate: TranslateService) {}

  ngOnChanges(): void {
    if (this.mode != ComponentModeEnum.ADD) {
      this.minDate = this.destinationFormGroup.controls.departureDate.value;
      this.destinationFormGroup.valueChanges.subscribe(() => {
        if (
          this.destinationFormGroup.controls.status.value !== DestinationStatus.MODIFIED &&
          this.destinationFormGroup.controls.status.value !== DestinationStatus.NEW
        ) {
          this.destinationFormGroup.controls.status.setValue(DestinationStatus.MODIFIED);
        }
      });
    }
  }

  getStartDate(event: MatDatepickerInputEvent<Date>) {
    this.minDate = event.value;

    this.destinationFormGroup.controls.returnDate.enable();
    if (!this.minDate || this.minDate > this.destinationFormGroup.controls.returnDate.value) {
      this.destinationFormGroup.controls.returnDate.setValue(null);
    }
  }

  public getErrorMessage(): string {
    {
      return this.translate.instant('USER.TRAVEL_REQUESTS.DESTINATION_REQUIRED');
    }
  }

  emitRoundTripChange(event: MatSlideToggleChange) {
    this.roundTrip.emit(event.checked);
  }

  deleteRow() {
    this.deleteDestination.emit();
  }
}
