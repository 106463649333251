import { Directive, Input } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';

@Directive({
  selector: 'mat-calendar[appMultiselect]',
})
export class CalendarDirective {
  @Input() selectedDates: Date[];
  @Input() handleSelect: (date: Date, dateIndex: number, selectedDates: Date[]) => void;
  constructor(calendar: MatCalendar<Date>) {
    calendar._dateSelected = (event) => {
      const date = event.value as Date;
      const index = this.selectedDates.findIndex((currDate) => {
        return currDate.getTime() === date.getTime();
      });
      if (index === -1) {
        this.selectedDates.push(date);
      } else {
        this.selectedDates.splice(index, 1);
      }
      this.handleSelect(date, index, this.selectedDates);
      calendar.updateTodaysDate();
    };
  }
}
