import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { effect, inject, Injectable, Injector, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';

import { IPagination } from '../models';
import { Timesheet } from '../models/timesheet';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private urlBase: string = 'api/timesheets';

  // Signals
  private _reports = signal<IPagination<Timesheet>>(undefined);
  readonly reports = this._reports.asReadonly();

  // Services
  private injector = inject(Injector);
  private httpClient = inject(HttpClient);

  constructor() {}

  fetchReports(pageSize?: number, pageIndex?: number, searchText?: string): void {
    let params = new HttpParams();

    if (pageSize !== undefined) {
      params = params.set('size', pageSize.toString());
    }

    if (pageIndex !== undefined) {
      params = params.set('page', pageIndex.toString());
    }

    if (searchText) {
      params = params.set('filter', searchText);
    }

    const signal = toSignal<IPagination<Timesheet>>(
      this.httpClient.get<IPagination<Timesheet>>(this.urlBase, {
        params,
      }),
      {
        injector: this.injector,
      },
    );

    effect(
      () => {
        if (!signal()) return this._reports.set(undefined);
        this._reports.set(signal());
      },
      { injector: this.injector, allowSignalWrites: true },
    );
  }
  downloadTimesheet(id: number): Observable<Blob> {
    const url = `${this.urlBase}/${id}/download`;
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      }),
    };

    return this.httpClient.get<Blob>(url, httpOptions);
  }

  approveTimesheet(id: number): Observable<Object> {
    const url = `${this.urlBase}/${id}/approve`;
    return this.httpClient.get(url);
  }

  async saveAsExcelFile({ buffer, fileName }: { buffer: Blob; fileName: string }) {
    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(data, `${fileName}`);
  }
}
