import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'base64ToImage',
})
export class Base64ToImagePipe implements PipeTransform {
  transform(value?: string, defaultImage = ''): string {
    if (!value) {
      return defaultImage;
    }

    return 'data:image/*;base64,' + value.replace(/^data:image\/.*;base64,/, '');
  }
}
