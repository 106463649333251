<mat-spinner *ngIf="isLoading"></mat-spinner>
<div [hidden]="isLoading">
  <h2 class="section-title">
    {{
      (mode !== 'ADD' ? 'USER.TRAVEL_REQUESTS.TITLE_EDIT' : 'USER.TRAVEL_REQUESTS.TITLE')
        | translate
    }}
  </h2>

  <div class="travel-form-holder">
    <form [formGroup]="travelForm" #formDirective="ngForm" (ngSubmit)="onSubmit()">
      <app-add-destination-form
        *ngFor="let destination of destinationFormArray.controls; let i = index"
        [destinationFormGroup]="destination"
        [length]="destinationFormArray.length"
        [isRoundTrip]="isRoundTrip"
        [mode]="mode"
        [isAdmin]="isAdmin"
        (roundTrip)="changeStateOfAddButton($event)"
        (deleteDestination)="deleteDestinationRow(i)"
      ></app-add-destination-form>

      <button
        class="add-destination-button"
        type="button"
        mat-raised-button
        color="primary"
        (click)="addDestination()"
        [disabled]="isRoundTrip || travelForm.controls.destination.value.length >= 10 || inView"
      >
        <mat-icon>add_circle_outline</mat-icon>
        Add Destination
      </button>

      <mat-form-field appearance="fill">
        <mat-label>
          {{ 'USER.TRAVEL_REQUESTS.PURPOSE_TITLE' | translate }}
        </mat-label>
        <textarea
          maxlength="255"
          rows="2"
          matInput
          type="text"
          formControlName="purpose"
          placeholder="{{ 'USER.TRAVEL_REQUESTS.PURPOSE_PLACEHOLDER' | translate }}"
          required
        ></textarea>
        <mat-hint align="end">{{ travelForm.controls.purpose.value?.length || 0 }}/255</mat-hint>
        <mat-error *ngIf="travelForm.controls.purpose.invalid">{{
          'USER.TRAVEL_REQUESTS.PURPOSE_REQUIRED' | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field color="primary" appearance="fill">
        <mat-label>
          {{ 'USER.TRAVEL_REQUESTS.PARTICIPANTS' | translate }}
        </mat-label>
        <mat-chip-grid aria-label="Employee selection" #employeeList>
          <mat-chip-row
            *ngFor="let employee of selectedEmployees"
            [removable]="true"
            [disabled]="inView"
            (removed)="removeEmployee(employee.id)"
          >
            {{ employee.firstNameEn + ' ' + employee.lastNameEn }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
          <input
            placeholder="{{
              inView ? '' : ('USER.TRAVEL_REQUESTS.PARTICIPANTS_PLACEHOLDER' | translate)
            }}"
            #employeeInput
            matInput
            [formControl]="participantsCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="employeeList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="onAddEmployee($event)"
            (focusout)="clearParticipantInputValue()"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selectEmployee($event)"
          [attr.disabled]="true"
        >
          <ng-container *ngFor="let employee of filteredEmployees | async">
            <mat-option
              [disabled]="inView"
              *ngIf="!isEmployeeInProject(employee)"
              [value]="employee"
            >
              {{ employee.firstNameEn + ' ' + employee.lastNameEn }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
        <mat-error *ngIf="travelForm.controls.participantDocuments.invalid">{{
          'USER.TRAVEL_REQUESTS.PARTICIPANTS_REQUIRED' | translate
        }}</mat-error>
      </mat-form-field>
      <mat-slide-toggle formControlName="abroadTrip" color="primary">
        {{ 'USER.TRAVEL_REQUESTS.ABROAD_TRIP' | translate }}</mat-slide-toggle
      >

      <!-- table -->
      <div class="table-holder">
        <table mat-table [dataSource]="participantsFormArray.value">
          <ng-container matColumnDef="participant">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'USER.TRAVEL_REQUESTS.PARTICIPANT' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.participant.firstNameEn + ' ' + element.participant.lastNameEn }}
            </td>
          </ng-container>
          <ng-container matColumnDef="boarding-cards">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'USER.TRAVEL_REQUESTS.BOARDING_CARDS' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <label
                for="file{{ element.participant.id }}"
                class="icon save"
                [ngClass]="inView ? 'icon-disabled' : ''"
              >
                <mat-icon
                  matTooltip="{{ 'USER.TRAVEL_REQUESTS.UPLOAD_BOARDING_CARDS' | translate }}"
                  [ngClass]="inView ? 'icon-disabled' : ''"
                  >cloud_upload</mat-icon
                ></label
              >

              <input
                (change)="uploadFile($event, element.participant.id, 'boardingCards')"
                [disabled]="inView"
                type="file"
                accept="image/png,image/jpeg,application/pdf"
                name="file{{ element.participant.id }}"
                class="file"
                id="file{{ element.participant.id }}"
                multiple
                hidden
              />
              <mat-icon
                *ngIf="element.boardingCards.length"
                matTooltip="{{ 'USER.TRAVEL_REQUESTS.VIEW_UPLOADED_DOCUMENTS' | translate }}"
                (click)="openUploadedFiles(element.participant.id, 'boardingCards')"
                class="icon save"
                >image_search</mat-icon
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="other-expenses">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'USER.TRAVEL_REQUESTS.OTHER_TRAVEL_EXPENSES' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <label
                for="document{{ element.participant.id }}"
                class="icon save"
                [ngClass]="inView ? 'icon-disabled' : ''"
              >
                <mat-icon
                  matTooltip="{{ 'USER.TRAVEL_REQUESTS.UPLOAD_OTHER_EXPENSES' | translate }}"
                  >cloud_upload</mat-icon
                ></label
              >
              <input
                (change)="uploadFile($event, element.participant.id, 'additionalDocuments')"
                type="file"
                [disabled]="inView"
                accept="image/png,image/jpeg,image/heic,application/pdf"
                name="document{{ element.participant.id }}"
                class="file"
                id="document{{ element.participant.id }}"
                multiple
                hidden
              />
              <mat-icon
                *ngIf="element.additionalDocuments.length"
                matTooltip="{{ 'USER.TRAVEL_REQUESTS.VIEW_UPLOADED_DOCUMENTS' | translate }}"
                (click)="openUploadedFiles(element.participant.id, 'additionalDocuments')"
                class="icon save"
                >image_search</mat-icon
              >
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="10">
              {{ 'USER.TRAVEL_REQUESTS.NO_DATA' | translate }}
            </td>
          </tr>
        </table>
        <div
          class="completed-radio"
          *ngIf="
            this.mode === componentModeEnum.EDIT || (isAdmin && this.mode !== componentModeEnum.ADD)
          "
        >
          <mat-radio-group
            aria-label="Select an option"
            (click)="handleCompleteClick($event, radioButton)"
          >
            <mat-radio-button
              value="completed"
              #radioButton
              [checked]="travelForm.controls.status.value === 'Completed'"
              >Completed</mat-radio-button
            >
          </mat-radio-group>
        </div>
      </div>

      <!-- submit -->
      <div class="button-container">
        <div class="spinner-container" *ngIf="buttonLoadingState">
          <mat-spinner diameter="24"></mat-spinner>
        </div>
        <button
          *ngIf="this.mode === componentModeEnum.ADD"
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="travelForm.invalid || buttonLoadingState"
        >
          {{ 'SHARED.SUBMIT' | translate }}
        </button>
      </div>
      <div class="edit-buttons">
        <button
          type="button"
          mat-button
          class="cancel-button"
          (click)="cancelUpdate()"
          *ngIf="this.mode !== componentModeEnum.ADD"
        >
          {{ (mode === 'VIEW' ? 'SHARED.CLOSE' : 'SHARED.CANCEL') | translate }}
        </button>
        <button
          mat-raised-button
          type="submit"
          [disabled]="travelForm.invalid"
          color="primary"
          *ngIf="
            this.mode === componentModeEnum.EDIT || (isAdmin && this.mode !== componentModeEnum.ADD)
          "
        >
          {{ 'SHARED.UPDATE' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
