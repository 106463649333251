import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DIALOG_BUTTONS } from '../../constants';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  readonly buttons = DIALOG_BUTTONS;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      description: string;
      sharedButtonClass: string;
      sharedButtonText: string;
      showCancelButton?: boolean;
    },
    public translate: TranslateService,
  ) {
    this.data.showCancelButton = this.data.showCancelButton ?? true;
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en/) ? browserLang : 'en');
  }
}
