import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'humanizeDate',
})
export class HumanizeDatePipe implements PipeTransform {
  transform(date: string | moment.Moment): string {
    if (typeof date === 'string') return moment(date).fromNow();

    return date.fromNow();
  }
}
