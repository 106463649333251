import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VacationHelperService {
  public distributeDaysLeftPerYear(daysLeft: number, limitYears: number): Map<number, number> {
    let currentYear = new Date().getFullYear();
    const daysLeftPerYear = new Map<number, number>();

    daysLeftPerYear.set(currentYear, 0);

    while (daysLeft > 0) {
      const daysLeftPerCurrentYear = Math.min(20, daysLeft);
      daysLeftPerYear.set(currentYear, daysLeftPerCurrentYear);
      daysLeft -= daysLeftPerCurrentYear;
      currentYear--;

      if (limitYears != null && limitYears === daysLeftPerYear.size) return daysLeftPerYear;
    }

    return daysLeftPerYear;
  }
}
