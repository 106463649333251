import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHoliday } from '../models/holiday.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HolidaysService {
  private urlBase: string = 'api/holidays';

  constructor(private httpClient: HttpClient) {}

  getHolidayById(id: number): Observable<IHoliday> {
    return this.httpClient.get<IHoliday>(`${this.urlBase}/${id}`);
  }

  getAllHolidays(): Observable<IHoliday[]> {
    return this.httpClient.get<IHoliday[]>(this.urlBase);
  }

  getAllHolidaysByYear(year: number): Observable<IHoliday[]> {
    return this.httpClient.get<IHoliday[]>(`${this.urlBase}/year/${year}`);
  }

  getAllClientHolidays(): Observable<IHoliday[]> {
    return this.httpClient.get<IHoliday[]>(`${this.urlBase}/client`);
  }

  createHoliday(holiday: IHoliday): Observable<IHoliday> {
    return this.httpClient.post<IHoliday>(`${this.urlBase}`, holiday);
  }

  updateHoliday(id: number, holiday: IHoliday): Observable<IHoliday> {
    return this.httpClient.put<IHoliday>(`${this.urlBase}/${id}`, holiday);
  }

  deleteHolidayById(id: number): Observable<IHoliday> {
    return this.httpClient.delete<IHoliday>(`${this.urlBase}/${id}`);
  }

  upload(file: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<string>(`${this.urlBase}/csv`, formData);
  }
}
