export const DIALOG_BUTTONS = {
  cancelButton: 'cancelButton',
  warningButton: 'warningButton',
  deleteButton: 'deleteButton',
  successButton: 'successButton',
  saveButton: 'saveButton',
};

export const REIMBURSEMENT_TYPES = {
  travel: 'Travel',
  sport: 'Sport',
  dentist: 'Dentist',
  tooling: 'Tooling',
};

export const VACATION_TYPES = [
  'VACATION',
  'SICK_LEAVE',
  'MATERNITY_PATERNITY',
  'OTHER_PAID_LEAVE',
  'LEAVE_OF_ABSENCE',
] as const;
export type VacationType = (typeof VACATION_TYPES)[number];

export const VACATION_SICK_TYPES = ['REGULAR_SICK_LEAVE', 'DREAMIX_SICK_LEAVE'] as const;
export type VacationSickType = (typeof VACATION_SICK_TYPES)[number];

export const VACATION_PAID_TYPES = [
  'MARRIAGE',
  'BLOOD_DONATION',
  'RELATIVE_DEATH',
  'COURT_HEARING',
  'MEMBER_OF_GOVERNMENT_MEETING',
  'TRAINING_FOR_DISASTER_SITUATION',
  'EMPLOYER_NOTICE_FOR_DISMISSAL',
  'OTHER_PROJECT',
] as const;
export type VacationPaidType = (typeof VACATION_PAID_TYPES)[number];

export type VacationSubType = VacationPaidType | VacationSickType;
