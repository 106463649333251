<div class="calendar-controls">
  <div class="calendar-navigations">
    <h4 class="calendar-month" (click)="changeMonth(0)">{{ currentMonthTitle }}</h4>
    <div class="calendar-month-nav align-center">
      <mat-icon (click)="changeMonth(-1)">keyboard_double_arrow_left</mat-icon>
      <div class="circle" (click)="changeMonth(0)"></div>
      <mat-icon (click)="changeMonth(1)"> keyboard_double_arrow_right</mat-icon>
    </div>
  </div>
  <div class="legend-container">
    <div class="legend-row-logged" *ngIf="!isCalendarHome">
      <div class="logged">
        <span class="label">logged time</span>
        <div class="legend"></div>
      </div>
      <div class="missing">
        <span class="label">missing Logged time</span>
        <div class="legend"></div>
      </div>
    </div>
    <div class="legend-row">
      <div class="legend-column" *ngFor="let vacationType of vacationIcons | keyvalue: enumOrder">
        <div [ngClass]="vacationType.key.toString().toLowerCase() + '-color-contrast'">
          <mat-icon>{{ vacationType.value }}</mat-icon>
        </div>
        <div
          class="legend-title"
          [ngClass]="vacationType.key.toString().toLowerCase() + '-color'"
          *ngIf="isCalendarHome"
        >
          {{ vacationTypes[vacationType.key] }}
        </div>
      </div>
    </div>
  </div>
</div>
