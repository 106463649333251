import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IEmployeeSimple } from '../../models';
import { AlertService, AlertType, EmployeeService } from '../../services';
import { Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonalCalendarService } from '../../services/personal-calendar.service';
import { IPersonalCalendar } from '../../models/personal-calendar.model';

@Component({
  selector: 'app-dialog',
  templateUrl: './calendar-create-dialog.component.html',
  styleUrls: ['./calendar-create-dialog.component.scss'],
})
export class CalendarCreateDialogComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  isCreateMode: boolean = true;
  isEditMode: boolean = false;
  employees: IEmployeeSimple[] = [];
  calendar: IPersonalCalendar;
  selectedEmployees: IEmployeeSimple[] = [];
  employeeNames: string[] = [];
  list: IEmployeeSimple[] = [];
  filter: string = '';
  form: FormGroup;
  userId: number;
  isButtonDisabled: boolean = false;

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { userId: number; calendar?: IPersonalCalendar; isEditMode?: boolean },
    public dialogRef: MatDialogRef<CalendarCreateDialogComponent>,
    public translate: TranslateService,
    private employeeService: EmployeeService,
    private alertService: AlertService,
    private personalCalendarService: PersonalCalendarService,
    private changeDetection: ChangeDetectorRef,
  ) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    this.userId = this.data.userId;
    if (this.data.calendar) {
      this.calendar = this.data.calendar;
    }
    if (this.data.isEditMode) {
      this.isEditMode = this.data.isEditMode;
    }
    this.resetForm();
    this.employeeService
      .getAllEmployeesSimple()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.employees = data;
          this.list = [...this.employees];
          this.employeeNames = this.selectedEmployees.map((employee) => employee.fullName);
        },
        () => {},
        () => {},
      );
  }

  ngAfterViewInit() {
    if (this.isEditMode) {
      this.form.setValue({
        title: this.calendar.title,
        description: this.calendar.description,
      });
      this.selectedEmployees = [...this.calendar.employees];
      this.employeeNames = this.selectedEmployees.map((employee) => employee.fullName);
    }
  }

  ngAfterViewChecked() {
    this.changeDetection.detectChanges();
  }

  resetForm() {
    this.form = new FormGroup({
      title: new FormControl('', Validators.required),
      description: new FormControl(''),
    });
  }

  createCalendar() {
    this.isButtonDisabled = true;
    const payload = {
      employeeId: this.userId,
      title: this.form.controls.title.value,
      description: this.form.controls.description.value,
      employees: [...this.selectedEmployees],
    };

    this.personalCalendarService
      .createPersonalCalendar(this.userId, payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.isButtonDisabled = false;
          this.personalCalendarService.setListOfCalendars(data);
          this.alertService.showAlert(
            this.translate.instant('HOME.CALENDAR.SUCCESSFULLY_CREATED'),
            AlertType.success,
          );
        },
        (error) => {
          this.alertService.showAlert(error.error, AlertType.error);
          this.isButtonDisabled = false;
        },
        () => {
          this.dialogRef.close();
        },
      );
  }

  updateCalendar() {
    this.isButtonDisabled = true;
    const payload = {
      id: this.calendar.id,
      employeeId: this.userId,
      title: this.form.controls.title.value,
      description: this.form.controls.description.value,
      employees: [...this.selectedEmployees],
    };

    if (this.isEditMode) {
      this.personalCalendarService
        .editPersonalCalendar(this.userId, payload, this.calendar.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data) => {
            this.personalCalendarService.setListOfCalendars(data);
            this.alertService.showAlert(
              this.translate.instant('ADMIN.LICENSES.SUCCESSFULLY_UPDATED'),
              AlertType.success,
            );
            this.isButtonDisabled = false;
          },
          (error) => {
            this.alertService.showAlert(error.error, AlertType.error);
            this.isButtonDisabled = false;
          },
          () => {
            this.dialogRef.close();
          },
        );
    }
  }

  removeOrAddEmployee(employee: IEmployeeSimple) {
    const index = this.selectedEmployees.findIndex((item) => item.id === employee.id);
    if (index >= 0) {
      this.selectedEmployees.splice(index, 1);
    } else {
      this.selectedEmployees.push(employee);
    }
    this.employeeNames = this.selectedEmployees.map((employee) => employee.fullName);
  }

  toggleMode() {
    this.isCreateMode = !this.isCreateMode;
    this.filter = '';
    this.list = [...this.employees];
  }

  deleteEmployee(index: number) {
    this.selectedEmployees.splice(index, 1);
    this.employeeNames.splice(index, 1);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
