<h2 class="section-title">
  {{
    (userView ? 'USER.TRAVEL_REQUESTS.TABLE.TITLE' : 'USER.TRAVEL_REQUESTS.TABLE.TITLE_ADMIN')
      | translate
  }}
</h2>

<form>
  <mat-form-field *ngIf="!userView" class="filter" color="primary" appearance="fill">
    <mat-label>
      {{ 'USER.TRAVEL_REQUESTS.FILTER_EMPLOYEE' | translate }}
    </mat-label>
    <mat-chip-grid aria-label="Filter by employees" #employeeList [formControl]="employeeCtrl">
      <mat-chip-row
        color="primary"
        *ngFor="let employee of selectedEmployees"
        [removable]="true"
        (removed)="removeEmployee(employee.id)"
      >
        {{ employee.firstNameEn + ' ' + employee.lastNameEn }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        #employeesInput
        matInput
        [matAutocomplete]="auto"
        [formControl]="employeeCtrl"
        [matChipInputFor]="employeeList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="onAddEmployee($event)"
        (focusout)="clearParticipantInputValue()"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="autocompleteSelect($event)"
      [attr.disabled]="true"
    >
      <ng-container *ngFor="let employee of filteredEmployees | async">
        <mat-option [value]="employee">
          {{ employee.firstNameEn + ' ' + employee.lastNameEn }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</form>

<div class="table-holder">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="id"
    matSortDirection="asc"
    (matSortChange)="sortData($event)"
  >
    <div class="highlight">
      <ng-container matColumnDef="request-id">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'USER.TRAVEL_REQUESTS.REQUEST_ID' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.businessTripId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="requester">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'USER.TRAVEL_REQUESTS.REQUESTER' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.requester }}
        </td>
      </ng-container>
      <ng-container matColumnDef="participant">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'USER.TRAVEL_REQUESTS.PARTICIPANT' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ getParticipantName(element) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="departureDate">
        <th mat-header-cell mat-sort-header="departureDate" *matHeaderCellDef>
          {{ 'USER.TRAVEL_REQUESTS.DEPARTURE_DATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.departureDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="returnDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'USER.TRAVEL_REQUESTS.RETURN_DATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.returnDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="start-destination">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'USER.TRAVEL_REQUESTS.START_DESTINATION' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.startDestination }}
        </td>
      </ng-container>
      <ng-container matColumnDef="end-destination">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'USER.TRAVEL_REQUESTS.END_DESTINATION' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.endDestination }}
        </td>
      </ng-container>
      <ng-container matColumnDef="update-delete-icons">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="icons-holder">
          <div class="flex">
            <mat-icon
              [class.hidden]="
                (userId === element.requesterId && this.userView) ||
                element.status === 'Completed' ||
                !this.userView
              "
              matTooltip="{{ 'USER.TRAVEL_REQUESTS.UPLOAD_DOCUMENTS' | translate }}"
              (click)="openUploadDocumentsDialog(element)"
              color="primary"
              >cloud_upload
            </mat-icon>
            <mat-icon
              [class.disabled]="
                (userId !== element.requesterId && this.userView) ||
                (element.status !== 'Created' && this.userView)
              "
              color="primary"
              (click)="editTravelRequest(element)"
              matTooltip="{{
                (element.status === 'Created' || isAdmin
                  ? 'USER.TRAVEL_REQUESTS.EDIT'
                  : 'USER.TRAVEL_REQUESTS.VIEW'
                ) | translate
              }}"
              class="edit-icon"
              >{{ element.status === 'Created' || isAdmin ? 'edit' : 'visibility' }}
            </mat-icon>
            <mat-icon
              [class.disabled]="
                (this.userView && element.status === 'Completed') ||
                (userId !== element.requesterId && this.userView)
              "
              matTooltip="{{ 'USER.TRAVEL_REQUESTS.DELETE' | translate }}"
              (click)="openDialog(element)"
              class="delete-icon"
              >cancel
            </mat-icon>
          </div>
        </td>
      </ng-container>
    </div>
    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: getDisplayedColumns()"
      [ngClass]="row.businessTripId === currentTripId ? 'highlight' : ''"
    ></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="10">
        {{ 'SHARED.COMPONENTS.TABLE_WRAPPER.NO_RECORDS_FOUND' | translate }}
      </td>
    </tr>
  </table>

  <mat-paginator
    *ngIf="!isDisabledPagination"
    class="result-table-paginator"
    [length]="dataLength"
    [pageSizeOptions]="[10, 20, 50]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
