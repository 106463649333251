import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, Signal, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import debounce from 'lodash.debounce';
import { Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { DIALOG_BUTTONS } from 'src/app/shared/constants';
import { DialogComponent } from 'src/app/shared/dialogs';
import {
  IEmployee,
  IEmployeeSearchRequest,
  IEmployeeSearchResponse,
  IPageable,
  IPagination,
} from 'src/app/shared/models';
import {
  AlertService,
  AlertType,
  EmployeeService,
  PageableHelperService,
  VacationHelperService,
} from 'src/app/shared/services';
import { EmployeeFiltersDialogComponent } from 'src/app/shared/components/employee-filters-dialog/employee-filters-dialog.component';

@Component({
  selector: 'app-employee-table',
  templateUrl: './employee-table.component.html',
  styleUrls: ['./employee-table.component.scss'],
})
export class EmployeeTableComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private readonly _storageKey = 'dms.employeeSearch';
  private _activeFilter: IEmployeeSearchRequest;
  private _activeFilterList: any[];

  readonly buttons = DIALOG_BUTTONS;
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'positionName',
    'role',
    'daysLeft',
    'action',
  ];
  pageable: IPageable;
  search: string;
  tableConfigSignal: Signal<IPagination<IEmployeeSearchResponse>>;

  get activeFilter(): IEmployeeSearchRequest {
    return this._activeFilter;
  }
  set activeFilter(value: IEmployeeSearchRequest) {
    this._activeFilter = value;
    this._activeFilterList = value
      ? [
          ...(value.skills?.requiredIds?.map((item: any) => ({
            ...item,
            term: 'skills',
            termProperty: 'requiredIds',
          })) || []),
          ...(value.skills?.optionalIds?.map((item: any) => ({
            ...item,
            term: 'skills',
            termProperty: 'optionalIds',
          })) || []),
          ...(value.certificates?.ids?.map((item: any) => ({
            ...item,
            term: 'certificates',
            termProperty: 'ids',
          })) || []),
          ...(value.position?.ids?.map((item: any) => ({
            ...item,
            term: 'position',
            termProperty: 'ids',
          })) || []),
        ]
      : null;
  }

  get activeFilterList(): any[] {
    return this._activeFilterList;
  }

  get storageKey(): string {
    return this.isAdminView ? `${this._storageKey}.admin` : this._storageKey;
  }

  @Input() isAdminView: boolean;
  @Input() page?: string;
  @Input() size?: string;
  @Input() title?: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private alertService: AlertService,
    private employeeService: EmployeeService,
    private pageableHelper: PageableHelperService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public helper: VacationHelperService,
    public translate: TranslateService,
  ) {
    this.tableConfigSignal = this.employeeService.employees;
  }

  applySearch() {
    if (!this.activeFilter) {
      this.activeFilter = {} as IEmployeeSearchRequest;
    }
    this.activeFilter.search = this.search;
    this.debouncedUpdateFilters();
  }

  clearFilter({
    filterId,
    term,
    termProperty,
  }: {
    filterId: number;
    term: string;
    termProperty: string;
  }) {
    const newFilter = { ...this.activeFilter };
    newFilter[term][termProperty] = newFilter[term][termProperty].filter(
      ({ id }: { id: number; name: string }) => id !== filterId,
    );
    this.activeFilter = newFilter;
    this.debouncedUpdateFilters();
  }

  clearSearch() {
    this.activeFilter.search = null;
    this.search = '';
    this.debouncedUpdateFilters();
  }

  clearFilters() {
    this.activeFilter = null;
    this.search = '';
    this.debouncedUpdateFilters();
  }

  exportAll() {
    // todo: hit api endpoint once it exists
  }

  fetchEmployees({
    searchRequest,
    forceFirstPage = false,
  }: {
    searchRequest?: IEmployeeSearchRequest;
    forceFirstPage?: boolean;
  }) {
    this.employeeService.fetchEmployees({
      params: this.getHttpParams(forceFirstPage),
      payload: searchRequest,
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    const existingFilter = JSON.parse(sessionStorage.getItem(this.storageKey));
    let searchRequest: IEmployeeSearchRequest;

    if (existingFilter) {
      this.activeFilter = existingFilter;
      this.search = existingFilter.search ?? null;
      searchRequest = this.employeeService.mapFormValueToRequest(this.activeFilter);
    }
    this.fetchEmployees({ searchRequest });
  }

  onTableGetData() {
    this.fetchEmployees({
      searchRequest: this.employeeService.mapFormValueToRequest(this.activeFilter),
    });
  }

  openDialog(id: number) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('ADMIN.EMPLOYEES.DELETE_CONFIRMATION').toString(),
          description: this.translate
            .instant('ADMIN.EMPLOYEES.DO_YOU_WANT_TO_DELETE_EMPLOYEE')
            .toString(),
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('ADMIN.EMPLOYEES.DELETE').toString(),
        },
      })
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        switchMap((result) => {
          if (result) {
            return this.deleteEmployee(id);
          }
          // Return an Observable of null if the dialog is cancelled
          return of(null);
        }),
      )
      .subscribe();
  }

  openFilterDialog() {
    this.dialog
      .open(EmployeeFiltersDialogComponent, {
        data: {
          filter: this.activeFilter,
          title: this.translate.instant('ADMIN.EMPLOYEES.FILTERS.FILTER_BY').toString(),
        },
        disableClose: true,
        width: '600px',
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: IEmployeeSearchRequest) => {
        this.activeFilter = this.activeFilter?.search
          ? ({ search: this.activeFilter.search } as IEmployeeSearchRequest)
          : ({} as IEmployeeSearchRequest);

        if (value) {
          this.activeFilter = { ...this.activeFilter, ...value };
        }
        this.debouncedUpdateFilters();
      });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 2000,
    });
  }

  private getHttpParams(forceFirstPage: boolean): HttpParams {
    this.pageable = this.pageable
      ? { ...this.pageable, page: forceFirstPage ? 0 : this.pageable.page }
      : this.pageableHelper.getPageable({
          page: forceFirstPage ? 0 : this.page,
          size: this.size,
        });
    return this.pageableHelper.getHttpParams(this.pageable);
  }

  private deleteEmployee(id: number): Observable<IEmployee> {
    return this.employeeService.deleteEmployeeById(id).pipe(
      takeUntil(this.destroy$),
      tap(() => {
        this.alertService.showAlert(
          this.translate.instant('ADMIN.EMPLOYEES.SUCCESSFULLY_REMOVED').toString(),
          AlertType.success,
        );
        // todo: refresh table
      }),
    );
  }

  private storeFilter(searchRequest: IEmployeeSearchRequest) {
    searchRequest
      ? sessionStorage.setItem(this.storageKey, JSON.stringify(searchRequest))
      : sessionStorage.removeItem(this.storageKey);
  }

  private debouncedUpdateFilters = debounce(this.updateActiveFilter, 500);
  private updateActiveFilter() {
    this.storeFilter(this.activeFilter);
    this.fetchEmployees({
      searchRequest: this.employeeService.mapFormValueToRequest(this.activeFilter),
      forceFirstPage: true,
    });
  }
}
