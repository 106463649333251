import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, AlertType, ProjectService } from '../../services';
import { Subject, takeUntil } from 'rxjs';
import { ProjectBase } from '../../models';
import { PersonalCalendarService } from '../../services/personal-calendar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './calendar-add-project-dialog.component.html',
  styleUrls: ['./calendar-add-project-dialog.component.scss'],
})
export class CalendarAddProjectDialogComponent implements OnInit, OnDestroy {
  allProjects: any;
  selectedProjects: ProjectBase[] = [];
  projectNames: string[] = [];
  userId: number;
  filter: string = '';
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId: number },
    public dialogRef: MatDialogRef<CalendarAddProjectDialogComponent>,
    public translate: TranslateService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private personalCalendarService: PersonalCalendarService,
  ) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    this.userId = this.data.userId;
    this.projectService
      .getProjectsSimple()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => (this.allProjects = data),
        () => {},
        () => {},
      );
  }

  removeOrAddProject(project: ProjectBase) {
    const index = this.selectedProjects.findIndex((item) => item.id === project.id);
    if (index >= 0) {
      this.selectedProjects.splice(index, 1);
    } else {
      this.selectedProjects.push(project);
    }
    this.projectNames = this.selectedProjects.map((project) => project.name);
  }

  addProject() {
    const projectIds = this.selectedProjects.map((project) => project.id);
    this.personalCalendarService
      .addProjects(this.userId, projectIds)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.personalCalendarService.setListOfCalendars(data);
          this.alertService.showAlert(
            this.translate.instant('ADMIN.LICENSES.SUCCESSFULLY_ADDED'),
            AlertType.success,
          );
        },
        (error) => this.alertService.showAlert(error.error.error, AlertType.error),
        () => {
          this.dialogRef.close();
        },
      );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
