
  <div class="title-holder align-center">
    <h1 mat-dialog-title>
      {{ data.title }}
    </h1>
    <button mat-icon-button class="close-button" color="primary" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="checkboxes-holder">
    <mat-checkbox [(ngModel)]="isEnabled.skills" color="primary">
      {{ 'ADMIN.EMPLOYEES.FILTERS.SKILLS_LABEL' | translate }}
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="isEnabled.certificates" color="primary">
      {{ 'ADMIN.EMPLOYEES.FILTERS.CERTIFICATES_LABEL' | translate }}
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="isEnabled.position" color="primary">
      {{ 'ADMIN.EMPLOYEES.FILTERS.POSITION_LABEL' | translate }}
    </mat-checkbox>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form">

      <div *ngIf="isEnabled.skills" formGroupName="skills">
        <label>{{ 'ADMIN.EMPLOYEES.FILTERS.SEARCH_BY_ANY_SKILLS' | translate }}</label>
        <app-multi-select-field
          [all]="allSkills$ | async"
          formControlName="optionalIds"
          [label]="'ADMIN.EMPLOYEES.FILTERS.SKILLS_LABEL' | translate"
          [placeholder]="'ADMIN.EMPLOYEES.FILTERS.SKILLS_PLACEHOLDER' | translate"
          [optionsToExclude]="form.get('skills.requiredIds').value"
          ></app-multi-select-field>

        <label>{{ 'ADMIN.EMPLOYEES.FILTERS.SEARCH_BY_ALL_SKILLS' | translate }}</label>
        <app-multi-select-field
          [all]="allSkills$ | async"
          formControlName="requiredIds"
          [label]="'ADMIN.EMPLOYEES.FILTERS.SKILLS_LABEL' | translate"
          [placeholder]="'ADMIN.EMPLOYEES.FILTERS.SKILLS_PLACEHOLDER' | translate"
          [optionsToExclude]="form.get('skills.optionalIds').value"
        ></app-multi-select-field>

        <div class="columns">
          <div class="slider-holder">
            <div class="slider-label">
              <label>{{ 'USER.MY_SETTINGS.PROFICIENCY_LEVEL' | translate }}</label>
              <mat-icon
                class="info-icon"
                matTooltip="{{ 'USER.MY_SETTINGS.PROFICIENCY_LEVEL_TOOLTIP' | translate }}"
                [matTooltipPosition]="'after'"
                matTooltipClass="tooltip"
                >info_outlined
              </mat-icon>
            </div>
  
            <mat-slider min="0" max="5" step="1" showTickMarks discrete>
              <input matSliderThumb formControlName="proficiencyLevel" />
            </mat-slider>
          </div>
  
          <mat-form-field color="accent" appearance="fill">
            <mat-label>{{ 'USER.MY_SETTINGS.YEARS_OF_EXPERIENCE' | translate }}</mat-label>
            <input matInput type="number" [min]="1" formControlName="yearsOfExperience" />
          </mat-form-field>
        </div>
      </div>
    
      <div *ngIf="isEnabled.certificates" formGroupName="certificates">
        <div *ngIf="isEnabled.skills" class="separator"></div>

        <app-multi-select-field
          [all]="allCertificates$ | async"
          formControlName="ids"
          [label]="'ADMIN.EMPLOYEES.FILTERS.CERTIFICATES_LABEL' | translate"
          [placeholder]="'ADMIN.EMPLOYEES.FILTERS.CERTIFICATES_PLACEHOLDER' | translate"
        ></app-multi-select-field>


        <mat-radio-group formControlName="searchByAll" required>
          <mat-radio-button color="primary" [value]="false">{{
            'ADMIN.EMPLOYEES.FILTERS.SEARCH_BY_ANY_CERTIFICATES' | translate
          }}</mat-radio-button><br>
          <mat-radio-button color="primary" [value]="true">{{
            'ADMIN.EMPLOYEES.FILTERS.SEARCH_BY_ALL_CERTIFICATES' | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="isEnabled.position" formGroupName="position">
        <div *ngIf="isEnabled.skills || isEnabled.certificates" class="separator"></div>

        <app-multi-select-field
          [all]="allPositions$ | async"
          formControlName="ids"
          [label]="'ADMIN.EMPLOYEES.FILTERS.POSITION_LABEL' | translate"
          nameProperty="positionName"
          [placeholder]="'ADMIN.EMPLOYEES.FILTERS.POSITION_PLACEHOLDER' | translate"
        ></app-multi-select-field>
      </div>
    
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button (click)="onClear()">
      {{ 'ADMIN.EMPLOYEES.FILTERS.CLEAR_FILTERS' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="onApply()">
      {{ 'ADMIN.EMPLOYEES.FILTERS.APPLY_FILTERS' | translate }}
    </button>
  </mat-dialog-actions>
