import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IInvalidTravelRequest,
  ITravelRequest,
  ITravelRequestPartial,
} from '../models/travel-request.model';

@Injectable({
  providedIn: 'root',
})
export class TravelRequestService {
  public urlBase: string = 'api/business-trip';
  private isTravelRequestDeleted = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {}

  public deleteFile: (
    employeeId: number,
    businessTripId: number,
    participantId: number,
    fileName,
  ) => Observable<any> = (employeeId, businessTripId, participantId, fileName) =>
    this.httpClient.delete<ITravelRequestPartial[]>(
      `${this.urlBase}/${employeeId}/${businessTripId}/${participantId}/${fileName}`,
    );

  public createTravelRequest(payload: FormData): any {
    return this.httpClient.post<any>(`${this.urlBase}/create`, payload);
  }

  public getTravelRequests(): Observable<ITravelRequestPartial[]> {
    return this.httpClient.get<ITravelRequestPartial[]>(`${this.urlBase}/all`);
  }

  public getTravelRequestById(userId, businessTripId): Observable<ITravelRequest> {
    return this.httpClient.get<ITravelRequest>(`${this.urlBase}/${userId}/${businessTripId}`);
  }

  public updateTravelRequest(requesterId, businessTripId, payload): Observable<any> {
    return this.httpClient.put<ITravelRequest>(
      `${this.urlBase}/${requesterId}/${businessTripId}/update`,
      payload,
    );
  }

  public deleteTravelRequest(userId, businessTripId): Observable<string> {
    return this.httpClient.delete<string>(`${this.urlBase}/${userId}/${businessTripId}`);
  }

  public getMissingTripDocuments(userId: number): Observable<IInvalidTravelRequest> {
    return this.httpClient.get<any>(`${this.urlBase}/${userId}/invalid-trips`);
  }

  public getTravelRequestsFilteredByDate(fromDate: string, toDate: string): Observable<any> {
    return this.httpClient.get(`${this.urlBase}/export?fromDate=${fromDate}&toDate=${toDate}`, {
      responseType: 'arraybuffer',
    });
  }

  public uploadtravelRequestDocuments(
    businessTripId: number,
    participantId: number,
    payload: FormData,
  ) {
    return this.httpClient.post<any>(
      `${this.urlBase}/${businessTripId}/${participantId}/upload_documents`,
      payload,
    );
  }

  setIsTravelRequestDeleted(value: boolean) {
    this.isTravelRequestDeleted.next(value);
  }

  getIsTravelRequestDeleted() {
    return this.isTravelRequestDeleted.asObservable();
  }
}
