import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private SETTINGS: MatSnackBarConfig = {
    duration: 9000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
  };

  constructor(
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {}

  public showAlert(message: string, type: AlertType): void {
    if (type === AlertType.success) {
      this.showSuccessAlert(message);
    } else if (type === AlertType.error) {
      this.showErrorAlert(message);
    }
  }

  public showSuccess(message: string): void {
    let translatedMessage = this.translate.instant(message);
    this.showSuccessAlert(translatedMessage);
  }

  public showError(message: string): void {
    let translatedMessage = this.translate.instant(message);
    this.showErrorAlert(translatedMessage);
  }

  //Replace all instances where this func is applicable
  public showServerError(error: HttpErrorResponse) {
    const message = `${error.statusText}: ${error.error}`;
    this.showErrorAlert(message);
  }

  private showSuccessAlert(message: string) {
    this._snackBar.open(message, '', {
      ...this.SETTINGS,
      panelClass: ['success-snackbar'],
    });
  }

  private showErrorAlert(message: string) {
    this._snackBar.open(message, 'Close', {
      ...this.SETTINGS,
      panelClass: ['error-snackbar'],
    });
  }
}

export enum AlertType {
  success,
  error,
}
