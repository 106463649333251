import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MaterialModule } from '../../material.module';
import { FormatBytesPipe } from '../../pipes/format-bytes.pipe';

@Component({
  selector: 'app-image-upload-base64',
  standalone: true,
  imports: [CommonModule, FormatBytesPipe, MaterialModule, TranslateModule],
  templateUrl: './image-upload-base64.component.html',
  styleUrls: ['./image-upload-base64.component.scss'],
})
export class ImageUploadBase64Component {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  @Input({ required: true }) controller!: FormControl;
  @Input() limit = 1024 * 1024; // 1MB
  @Input() stylingOptions = {
    fullWidth: false,
    noBackground: false,
    reverseButtons: false,
  }
  @Input() uploadButtonText: string;

  constructor(public translate: TranslateService) {}

  handleUpload(file: File) {
    this.controller.setValue(null);
    this.controller.markAsTouched();

    if (!file) return;

    if (!file.type.startsWith('image')) {
      this.controller.setErrors({ type: true });
      return;
    } else {
      this.controller.setErrors(null);
    }

    if (file.size > this.limit) {
      this.controller.setErrors({ size: true });
      return;
    } else {
      this.controller.setErrors(null);
    }

    if (this.controller.invalid) {
      this.fileInput.nativeElement.value = null;
      return;
    }

    const reader = new FileReader();

    reader.onload = () => this.controller.setValue(reader.result as string);

    reader.readAsDataURL(file);
  }

  removeFile() {
    this.controller.setValue(null);
    this.controller.setErrors(null);
    this.fileInput.nativeElement.value = null;
  }
}
