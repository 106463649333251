import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { IPageable } from '../../models/pageable.model';

@Injectable({
  providedIn: 'root',
})
export class PageableHelperService {
  public getPageable(params?: Params): IPageable {
    const pageable: IPageable = {
      page: 0,
      size: 10,
      field: '',
      order: '',
    };
    if (!params) return pageable;

    if (/^\+?([1-9]\d*)$/.test(params.page)) {
      pageable.page = params.page - 1;
    }
    if (/^\+?([1-9]\d*)$/.test(params.size)) {
      pageable.size = params.size;
    }

    if (params.sortBy) {
      const sort = params.sortBy.split(',');
      pageable.field = sort[0];
      pageable.order = sort[1] ? sort[1] : 'asc';
    }

    return pageable;
  }

  public getHttpParams(pageable: IPageable): HttpParams {
    let params = new HttpParams();

    if (pageable.page !== undefined && pageable.size !== undefined) {
      params = params
        .append('page', pageable.page.toString())
        .append('size', pageable.size.toString());
    }

    if (pageable.name) {
      params = params.append('name', pageable.name);
    }

    if (pageable.field && pageable.order) {
      params = params.append('field', pageable.field).append('order', pageable.order);
    }

    return params;
  }

  public getPageableUrl(pageable: IPageable, router: Router): string {
    return router
      .createUrlTree([], {
        queryParams: {
          page: pageable.page + 1,
          size: pageable.size,
          name: pageable.name,
          sortBy:
            pageable.field && pageable.order
              ? pageable.field + ',' + pageable.order.toLowerCase()
              : null,
        },
        queryParamsHandling: 'merge',
        preserveFragment: true,
      })
      .toString();
  }
}
