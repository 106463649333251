import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DialogComponent } from '../../dialogs';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_BUTTONS } from '../../constants';
import { TravelRequestService } from '../../services/travel-request.service';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { ITravelRequestPartial } from '../../models/travel-request.model';
import { MatSort, Sort } from '@angular/material/sort';
import { AlertService, AlertType, EmployeeService, LoginService } from '../../services';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EmployeePartial } from '../../models';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { UploadTravelDocumentsDialogComponent } from 'src/app/travel-requests/upload-travel-documents-dialog/upload-travel-documents-dialog.component';

@Component({
  selector: 'app-travel-requests-table',
  templateUrl: './travel-requests-table.component.html',
  styleUrls: ['./travel-requests-table.component.scss'],
})
export class TravelRequestsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  _filter: string;
  @Input() set filter(filter: string) {
    this._filter = filter;
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  @Input() currentTripId?: number;
  @Input() cancelUpdate?: (tripId: number) => void;

  get filter(): string {
    return this._filter;
  }

  @Input() userView: boolean;
  @Output()
  requestToEdit: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  isAdmin: boolean = this.loginService.isUserRoleAdmin();
  userId: number = this.loginService.getUserId();
  isDisabledPagination: boolean = false;
  @ViewChild('employeesInput') employeeInput: ElementRef<HTMLInputElement>;
  employees: EmployeePartial[] = [];
  employeeCtrl = new FormControl('');
  selectedEmployees: EmployeePartial[] = [];
  filteredEmployees: Observable<EmployeePartial[]>;
  displayedColumnsUser: string[] = [
    'request-id',
    'requester',
    'participant',
    'departureDate',
    'returnDate',
    'start-destination',
    'end-destination',
    'update-delete-icons',
  ];
  displayedColumnsAdmin: string[] = [
    'request-id',
    'requester',
    'departureDate',
    'returnDate',
    'start-destination',
    'end-destination',
    'update-delete-icons',
  ];

  dataSource = new MatTableDataSource<ITravelRequestPartial>();

  dataLength: number;
  sortedData: ITravelRequestPartial[];
  readonly buttons = DIALOG_BUTTONS;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  private destroy$ = new Subject<void>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    public dialog: MatDialog,
    public loginService: LoginService,
    private employeeService: EmployeeService,
    public translate: TranslateService,
    private travelRequestService: TravelRequestService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.getTravelRequests();
    this.getAllEmployees();
    this.filteredEmployees = this.employeeCtrl.valueChanges.pipe(
      map((employee: any) => this.filterEmployeesByName(employee)),
    );
  }

  getDisplayedColumns() {
    if (this.userView) {
      return this.displayedColumnsUser;
    } else {
      return this.displayedColumnsAdmin;
    }
  }

  private filterEmployeesByName(value: string): EmployeePartial[] {
    const availableEmployees = this.employees.filter((v) => {
      const match = this.selectedEmployees.find((selected) => selected.id === v.id);
      return !match;
    });
    if (!value || typeof value !== 'string') {
      return availableEmployees;
    }
    const filterValue = value.toLowerCase();
    return availableEmployees.filter((employee) =>
      `${employee.firstNameEn} ${employee.lastNameEn}`.toLowerCase().includes(filterValue),
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource._updateChangeSubscription();
    this.dataSource.data = this.dataSource.data.filter(
      (record: ITravelRequestPartial) => record.requester === 'q',
    );
  }

  public onAddEmployee(event: MatChipInputEvent): void {
    const input = event.input;
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  private rerenderTable() {
    this.employeeCtrl.setValue('');
    this.filterTable();
    this.table.renderRows();
  }

  public removeEmployee(id: number): void {
    this.selectedEmployees = this.selectedEmployees.filter((employee) => employee.id !== id);
    this.rerenderTable();
  }

  getAllEmployees() {
    this.employeeService.getPartialEmployees().subscribe((data) => {
      this.employees = data;
    });
  }

  getParticipantName(element: ITravelRequestPartial) {
    if (element.requesterId === this.userId) return '';
    const participant = element.participants.find((participant) => participant.id === this.userId);
    return participant ? participant.name : '';
  }

  getTravelRequests() {
    this.isLoading = true;
    this.travelRequestService
      .getTravelRequests()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ITravelRequestPartial[]) => {
        let businessTrips = [];
        if (this.userView) {
          data.forEach((businessTrip) => {
            if (businessTrip.requesterId === this.userId) {
              businessTrips.push(businessTrip);
            } else {
              businessTrip.participants.forEach((participant) => {
                if (participant.id === this.userId) {
                  businessTrips.push(businessTrip);
                }
              });
            }
          });
        } else {
          businessTrips = data;
        }
        this.isLoading = false;
        this.dataSource.data = businessTrips;
        this.dataLength = businessTrips.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'departureDate':
          return this.compare(a.departureDate, b.departureDate, isAsc);
        case 'returnDate':
          return this.compare(a.returnDate, b.returnDate, isAsc);
        default:
          return 0;
      }
    });
    this.table.renderRows();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (new Date(a) < new Date(b) ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public filterTable() {
    this.dataSource.filterPredicate = (record: ITravelRequestPartial) => {
      if (!this.selectedEmployees.length) {
        return true;
      }
      const match = this.selectedEmployees.find(
        (v) => `${v.firstNameEn} ${v.lastNameEn}`.toLowerCase() === record.requester.toLowerCase(),
      );
      return Boolean(match);
    };
    this.dataSource.filter = 'update';
  }

  public autocompleteSelect(event: MatAutocompleteSelectedEvent) {
    this.selectedEmployees.push(event.option.value);
    this.employeeInput.nativeElement.value = '';
    this.rerenderTable();
  }

  public openDialog(element: ITravelRequestPartial): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('USER.TRAVEL_REQUESTS.DELETE_CONFIRMATION').toString(),
          description:
            this.translate.instant('USER.TRAVEL_REQUESTS.DELETE_CONFIRMATION_DESCRIPTION') +
            ` ID: ${element.businessTripId}?`.toString(),
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('USER.TRAVEL_REQUESTS.DELETE').toString(),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const requesterId = element.requesterId;
          this.travelRequestService
            .deleteTravelRequest(requesterId, element.businessTripId)
            .subscribe(
              () => {
                if (this.cancelUpdate) {
                  this.cancelUpdate(element.businessTripId);
                }
                this.getTravelRequests();
                this.table.renderRows();
              },
              () => {
                this.alertService.showAlert(
                  this.translate.instant('USER.TRAVEL_REQUESTS.UNSUCCESSFUL_DELETE'),
                  AlertType.error,
                );
              },
              () => {
                this.alertService.showAlert(
                  this.translate.instant('USER.TRAVEL_REQUESTS.SUCCESSFUL_DELETE'),
                  AlertType.success,
                );
                if (this.userView) {
                  this.travelRequestService.setIsTravelRequestDeleted(true);
                }
              },
            );
        }
      });
  }

  public openUploadDocumentsDialog(element: ITravelRequestPartial): void {
    this.dialog
      .open(UploadTravelDocumentsDialogComponent, {
        data: element,
        width: '400px',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
        }
      });
  }

  public clearParticipantInputValue() {
    this.employeeInput.nativeElement.value = '';
  }

  public editTravelRequest(data: ITravelRequestPartial) {
    this.requestToEdit.emit(data);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
