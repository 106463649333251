<div class="actions" [ngClass]="{
  'full-width': stylingOptions.fullWidth,
  'reverse-buttons': stylingOptions.reverseButtons,
}">
  <button
    *ngIf="controller.value || (controller.errors && (controller.dirty || controller.touched))"
    type="button"
    mat-mini-fab
    [color]="stylingOptions.noBackground ? '' : 'warn'"
    (click)="removeFile()"
  >
    <mat-icon>delete</mat-icon>
  </button>

  <button type="button" mat-raised-button (click)="fileInput.click()" color="primary">
    {{ uploadButtonText ?? 'SHARED.UPLOAD_IMAGE' | translate }}
  </button>
</div>
<input
  #fileInput
  type="file"
  hidden
  (change)="handleUpload($event.target.files[0])"
  [accept]="'image/*'"
/>

<mat-error *ngIf="controller.invalid && (controller.dirty || controller.touched)">
  <ng-container *ngIf="controller.errors?.required">{{
    'SHARED.VALIDATION.REQUIRED' | translate
  }}</ng-container>
  <ng-container *ngIf="controller.errors?.size">{{
    'SHARED.VALIDATION.SIZE' | translate: { size: limit | formatBytes }
  }}</ng-container>
  <ng-container *ngIf="controller.errors?.type">{{
    'SHARED.VALIDATION.TYPE' | translate
  }}</ng-container>
</mat-error>
