import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import {
  IAllCertificates,
  IAllSkills,
  IEmployeeSearchRequest,
  IPosition,
} from 'src/app/shared/models';
import {
  EmployeeCertificatesService,
  EmployeeSkillsService,
  PositionService,
} from 'src/app/shared/services';

@Component({
  selector: 'app-employee-filters-dialog',
  templateUrl: './employee-filters-dialog.component.html',
  styleUrls: ['./employee-filters-dialog.component.scss'],
})
export class EmployeeFiltersDialogComponent implements OnInit {
  allCertificates$: Observable<IAllCertificates[]>;
  allPositions$: Observable<IPosition[]>;
  allSkills$: Observable<IAllSkills[]>;
  form: UntypedFormGroup;
  isEnabled = {
    certificates: false,
    position: false,
    skills: false,
  };

  constructor(
    private dialogRef: MatDialogRef<EmployeeFiltersDialogComponent>,
    private employeeCertificatesService: EmployeeCertificatesService,
    private employeeSkillsService: EmployeeSkillsService,
    private readonly formBuilder: UntypedFormBuilder,
    private positionService: PositionService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      filter: IEmployeeSearchRequest;
      title: string;
    },
  ) {}

  ngOnInit() {
    this.initForm();
    this.load();
  }

  onApply() {
    const { certificates, position, skills } = this.form.value;
    const result = {} as IEmployeeSearchRequest;

    if (this.isEnabled.certificates) result.certificates = certificates;
    if (this.isEnabled.position) result.position = position;
    if (this.isEnabled.skills) result.skills = skills;

    this.dialogRef.close(Object.keys(result).length > 0 ? result : null);
  }

  onClear() {
    this.form.reset();
  }

  onClose() {
    this.dialogRef.close();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      certificates: this.formBuilder.group({
        ids: [null],
        searchByAll: [false],
      }),
      position: this.formBuilder.group({
        ids: [null],
      }),
      skills: this.formBuilder.group({
        proficiencyLevel: [null, [Validators.pattern('^(0|1|2|3|4|5)$')]],
        optionalIds: [null],
        requiredIds: [null],
        yearsOfExperience: [null],
      }),
    });

    if (this.data.filter) {
      this.form.patchValue(this.data.filter);

      this.isEnabled.skills =
        this.data.filter.skills?.requiredIds?.length > 0 ||
        this.data.filter.skills?.optionalIds?.length > 0;
      this.isEnabled.certificates = this.data.filter.certificates?.ids?.length > 0;
      this.isEnabled.position = this.data.filter.position?.ids?.length > 0;
    }
  }

  private load() {
    this.allCertificates$ = this.employeeCertificatesService.getAllCertificates();
    this.allPositions$ = this.positionService.getAllPositions();
    this.allSkills$ = this.employeeSkillsService.getAllSkills();
  }
}
