import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { PersonalCalendarRequest } from '../models/personal-calendar.model';
import { IEmployee } from '../models';
import { ICalendarV2Request, ICalendarV2RequestLoggedTime } from '../models/request-calendar.model';

@Injectable({
  providedIn: 'root',
})
export class PersonalCalendarService {
  private urlBase: string = 'api/personal-calendar';
  private listOfCalendars$ = new BehaviorSubject<any[]>([]);

  constructor(private httpClient: HttpClient) {}

  createPersonalCalendar(userId: number, payload: any): Observable<any> {
    return this.httpClient.post(`${this.urlBase}/employee/${userId}`, payload);
  }

  editPersonalCalendar(userId: number, payload: any, calendarId: number): Observable<any> {
    return this.httpClient.put(`${this.urlBase}/${calendarId}/employee/${userId}`, payload);
  }

  getPersonalCalendarById(calendarId: number): Observable<any> {
    return this.httpClient.get(`${this.urlBase}/${calendarId}/employees`);
  }

  addProjects(userId: number, projectIds: number[]): Observable<any> {
    return this.httpClient.post(`${this.urlBase}/employee/${userId}/projects`, projectIds);
  }

  submitCalendarsAndProjects(userId: number, payload: any): Observable<any> {
    return this.httpClient.post(`${this.urlBase}/employee/${userId}/calendar/submit`, payload);
  }

  getAllCalendars(userId: number): Observable<any> {
    return this.httpClient.get(`${this.urlBase}/employee/${userId}`);
  }

  getAllRequestsInCheckedCalendars(
    month: string,
    employeeId: IEmployee['id'],
  ): Observable<ICalendarV2Request[]> {
    return this.httpClient.get<ICalendarV2Request[]>(
      `${this.urlBase}/employee/${employeeId}/${month}`,
    );
  }

  getTimeLogging(
    projectId: number,
    fromDate: string,
    toDate: string,
  ): Observable<ICalendarV2RequestLoggedTime[]> {
    const params = new URLSearchParams({ fromDate, toDate });

    return this.httpClient.get<ICalendarV2RequestLoggedTime[]>(
      `/api/time-logging/projects/${projectId}?${params.toString()}`,
    );
  }

  deletePersonalCalendar(id: number, userId: number): Observable<any> {
    return this.httpClient.delete(`${this.urlBase}/${id}/employee/${userId}`);
  }

  deleteProjectFromCalendar(id: number, userId: number): Observable<any> {
    return this.httpClient.delete(`${this.urlBase}/employee/${userId}/project/${id}`);
  }

  setListOfCalendars(list: PersonalCalendarRequest[]) {
    this.listOfCalendars$.next(list);
  }

  getListOfCalendars() {
    return this.listOfCalendars$.asObservable();
  }
}
