import { VacationTypeEnum } from 'src/app/shared/enums';

type VacationIcons = {
  [key in keyof typeof VacationTypeEnum]?: string;
};

export const vacationIcons: VacationIcons = {
  VACATION: 'beach_access',
  OTHER_PAID_LEAVE: 'monetization_on',
  MATERNITY_PATERNITY: 'stroller',
  LEAVE_OF_ABSENCE: 'money_off',
  WORK_ON_HOLIDAYS: 'work',
  SICK_LEAVE: 'sick',
};
