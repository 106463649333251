import { ModuleWithProviders, NgModule } from '@angular/core';
import { TimeLogController } from './time-log.controller';

@NgModule()
export class ServiceModule {
  static forRoot(): ModuleWithProviders<ServiceModule> {
    return {
      ngModule: ServiceModule,
      providers: [TimeLogController],
    };
  }
}
