import { effect, Injectable, Injector, Signal, signal } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable, shareReplay } from 'rxjs';
import {
  IPosition,
  IPositionSearchRequest,
  IPositionSearchResponse,
} from '../models/position.model';
import { IPageable, IPagination } from '../models';
import { AlertService, AlertType } from './alert.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { IAdministrationItem } from '../models/administrationItem.model';

@Injectable({
  providedIn: 'root',
})
export class PositionService {
  private urlBase: string = 'api/positions';
  private positions$: Observable<IPosition[]>;
  pageable: IPageable;

  // Signals
  private _positions = signal<IPagination<IPositionSearchResponse> | undefined>(undefined);

  get positions(): Signal<IPagination<IPositionSearchResponse> | undefined> {
    return this._positions.asReadonly();
  }

  constructor(
    private alertService: AlertService,
    private httpClient: HttpClient,
    private injector: Injector,
  ) {}

  getAllPositions(): Observable<IPosition[]> {
    this.positions$ = this.positions$ ?? this.fetchAllPositions().pipe(shareReplay());
    return this.positions$;
  }

  private fetchAllPositions(): Observable<IPosition[]> {
    return this.httpClient.get<IPosition[]>(this.urlBase);
  }

  fetchPositions({ params, payload }: { params: HttpParams; payload?: IPositionSearchRequest }) {
    const responseSignal = toSignal(
      this.httpClient
        .post<IPagination<IPositionSearchResponse>>(`${this.urlBase}/search`, payload, {
          params,
        })
        .pipe(
          map((response) => ({
            ...response,
            content: response.content.map((position) => ({
              ...position,
              name: position.positionName,
            })),
          })),
        ),
      { injector: this.injector },
    );

    effect(
      () => {
        const data = responseSignal();
        if (!data) {
          this._positions.set(undefined);
          return;
        }

        try {
          this._positions.set(data);
        } catch (error) {
          this.alertService.showAlert(error, AlertType.error);
        }
      },
      { injector: this.injector, allowSignalWrites: true },
    );
  }

  createPosition(position: { title: string }): Observable<IPosition> {
    return this.httpClient.post<IPosition>(`${this.urlBase}/create`, position);
  }

  updatePosition(position: IAdministrationItem): Observable<IPosition> {
    const mappedPosition = { id: position.id, title: position.name };
    return this.httpClient.put<IPosition>(`${this.urlBase}/update`, mappedPosition);
  }

  deletePositionById(id: number): Observable<IPosition> {
    return this.httpClient.delete<IPosition>(`${this.urlBase}/${id}`);
  }
}
