import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { IAllSkills, IEmployeeSkill } from '../models/employee-skills.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeeSkillsService {
  private urlBase: string = 'api/employee-skills';
  private skills$: Observable<IAllSkills[]>;

  constructor(private httpClient: HttpClient) {}

  getAllSkills(): Observable<IAllSkills[]> {
    this.skills$ = this.skills$ ?? this.fetchAllSkills().pipe(shareReplay());
    return this.skills$;
  }

  getEmployeeSkills(employeeId: number): Observable<IEmployeeSkill[]> {
    return this.httpClient.get<IEmployeeSkill[]>(`${this.urlBase}/${employeeId}`);
  }

  createEmployeeSkill(employeeId: number, payload: any): Observable<IEmployeeSkill[]> {
    return this.httpClient.post<IEmployeeSkill[]>(`${this.urlBase}/${employeeId}`, payload);
  }

  deleteEmployeeSkill(employeeId: number, skillId: number): Observable<IEmployeeSkill[]> {
    return this.httpClient.delete<IEmployeeSkill[]>(
      `${this.urlBase}/${employeeId}?skillId=${skillId}`,
    );
  }

  private fetchAllSkills(): Observable<IAllSkills[]> {
    return this.httpClient.get<IAllSkills[]>('api/skill');
  }
}
