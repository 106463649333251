import { Injectable } from '@angular/core';
import { HolidaysService } from './holidays.service';
import { Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { IHoliday } from '../models/holiday.model';
import { tap, switchMap } from 'rxjs/operators';

/**
 * SharedDataService
 *
 * A centralized service for fetching and managing holiday and employee data.
 *
 * - **Instant Initialization**: On creation, the service fetches holiday and employee data.
 * - **Reactive Data Streams**: Utilizes `BehaviorSubject`s for real-time data updates.
 *   Access data via `holidaysState$` and `employeesState$`.
 * - **Data Refresh Mechanism**: For fresh data, invoke `triggerFetchHolidays`.
 */
@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private readonly fetchHolidays$$ = new ReplaySubject<boolean>(1);

  public holidaysState$: Observable<IHoliday[]>;
  private readonly holidaysState$$ = new BehaviorSubject<IHoliday[]>([]);

  constructor(private holidayService: HolidaysService) {
    this.holidaysState$ = this.holidaysState$$.asObservable();

    // Set up the fetching logic as soon as the service is instantiated
    this.setupFetchLogic();
  }

  private setupFetchLogic(): void {
    this.fetchFromService<IHoliday>(this.fetchHolidays$$, this.holidaysState$$, () =>
      this.holidayService.getAllHolidays(),
    );

    this.triggerFetchHolidays();
  }

  private fetchFromService<T>(
    trigger$: ReplaySubject<boolean>,
    stateSubject$: BehaviorSubject<T[]>,
    fetchServiceFn: () => Observable<T[]>,
    sorterFn?: (a: T, b: T) => number,
  ): void {
    trigger$
      .asObservable()
      .pipe(
        switchMap(() => fetchServiceFn()),
        tap((data) => {
          if (sorterFn) {
            data.sort(sorterFn);
          }
          stateSubject$.next(data);
        }),
      )
      .subscribe();
  }

  public triggerFetchHolidays(): void {
    this.fetchHolidays$$.next(true);
  }
}
