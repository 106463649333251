import { Injectable } from '@angular/core';
import { SharedDataService } from '../shared-data.service';
import { IHoliday } from '../../models';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

export interface DayDetails {
  date: number;
  header: string;
  label?: string;
  isWeekend?: boolean;
  isHoliday?: boolean;
  isToday?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CalendarHelper {
  private allHolidays: Array<IHoliday>;

  constructor(
    private sharedDataService: SharedDataService,
    public translate: TranslateService,
  ) {
    this.sharedDataService.holidaysState$.subscribe((holidays) => (this.allHolidays = holidays));
  }

  private isHoliday(date: string): boolean {
    return this.allHolidays.some((h) => h.date === date);
  }

  private isWorkingDay(date: string | null): boolean {
    if (!date || !this.allHolidays) {
      return false;
    }
    const isWeekend: boolean = moment(date).day() === 0 || moment(date).day() === 6;
    const isHoliday: boolean = this.isHoliday(date);

    return !isWeekend && !isHoliday;
  }

  private getDayHeader = (currentMonth: moment.Moment, day: number) => {
    return currentMonth.startOf('month').set('date', day).format('dd').slice(0, 2).toUpperCase();
  };

  private getDayLabel = (day: DayDetails, momentDay: moment.Moment) => {
    let label = '';

    if (day.isToday) label += this.translate.instant('HOME.CALENDAR.TODAY').toString() + '\n';
    if (day.isWeekend) label += this.translate.instant('HOME.CALENDAR.WEEKEND').toString() + '\n';
    if (day.isHoliday) {
      label += this.allHolidays.find(
        (hol) =>
          new Date(hol.date).toLocaleDateString() === momentDay.toDate().toLocaleDateString(),
      ).description;
    }

    return label;
  };

  private isToday(date: moment.Moment): boolean {
    const currentDate = moment();
    return currentDate.isSame(date, 'day');
  }

  public calculateWorkingDays(fromDate: moment.Moment, toDate: moment.Moment, pattern?: string) {
    const days = [];

    let diff = toDate?.diff(fromDate, 'day');

    if (typeof diff === 'number') {
      diff += 1;
    } else {
      diff = 0;
    }

    // We assume full day pattern day '3' if none is provided
    const currentPattern = !!pattern ? pattern : '3'.repeat(diff);

    let nonWorkingDays = 0;
    for (let i = 0; i < diff; i++) {
      const date = fromDate.clone().add(i, 'day');

      if (this.isWorkingDay(date.format('YYYY-MM-DD'))) {
        const dayPattern = currentPattern[i - nonWorkingDays];

        days.push({
          date,
          morning: ['1', '3'].includes(dayPattern),
          afternoon: ['2', '3'].includes(dayPattern),
        });
      } else {
        nonWorkingDays += 1;
      }
    }

    return days;
  }

  public getMonthDays = (currentMonth: moment.Moment) => {
    const temporaryDetails: DayDetails[] = [];
    const daysInSelectedMonth = moment(currentMonth).daysInMonth();
    const currentDay = moment(currentMonth).startOf('month');

    for (let date = 1; date <= daysInSelectedMonth; date++) {
      const day: DayDetails = {
        date: currentDay.date(),
        header: this.getDayHeader(currentMonth, currentDay.date()),
        isHoliday: this.isHoliday(currentDay.format('YYYY-MM-DD')),
        isWeekend: currentDay.day() === 0 || currentDay.day() === 6,
        isToday: this.isToday(currentDay),
      };

      day.label = this.getDayLabel(day, currentDay);

      temporaryDetails.push({ ...day });
      currentDay.add(1, 'day');
    }

    return temporaryDetails;
  };
}
