import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuItem } from '../models/menuItem.model';

@Injectable({
  providedIn: 'root',
})
export class ObservablesService {
  public requestFormSent: Subject<boolean> = new Subject<boolean>();
  public dynamicBreadcrumbs: Subject<MenuItem[]> = new Subject<MenuItem[]>();
  public updateCalendar: Subject<boolean> = new Subject<boolean>();
}
