import { effect, inject, Injectable, Injector, signal } from '@angular/core';
import { IPagination } from '../models/pagination.model';
import { IReferral } from '../models/referral.model';
import { toSignal } from '@angular/core/rxjs-interop';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AlertService, AlertType } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralsService {
  readonly urlBase: string = 'api/employees';

  //signals
  private _referrals = signal<IPagination<IReferral>>(undefined);
  readonly referrals = this._referrals.asReadonly();

  //services
  private httpClient: HttpClient = inject(HttpClient);
  private injector: Injector = inject(Injector);
  private alertService: AlertService = inject(AlertService);
  constructor() {}

  fetchReferrals(params: HttpParams): void {
    const signal = toSignal(
      this.httpClient.get<IPagination<IReferral>>(`${this.urlBase}/referrals`, {
        params,
      }),
      { injector: this.injector },
    );
    effect(
      () => {
        if (!signal()) return this._referrals.set(undefined);

        // error handling
        try {
          signal().rowCss = this.isForCurrentMonth;
        } catch (e) {
          this.alertService.showAlert(e, AlertType.error);
        }
        this._referrals.set(signal());
      },
      { injector: this.injector, allowSignalWrites: true },
    );
  }

  isForCurrentMonth(row: IReferral): boolean {
    const currentDate = new Date();
    const sixMonthsAgoStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, 1);

    const sixMonthsAgoEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 0);

    const referralStartDate = new Date(row.referralStartDate).setHours(0, 0, 0, 0);

    return (
      referralStartDate >= sixMonthsAgoStart.getTime() &&
      referralStartDate <= sixMonthsAgoEnd.getTime()
    );
  }
}
