import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IEmployeeSimple } from 'src/app/shared/models';

@Component({
  selector: 'app-autocomplete-person',
  templateUrl: './autocomplete-person.component.html',
  styleUrls: ['./autocomplete-person.component.scss'],
})
export class AutocompletePersonComponent {
  @Input() required = false;
  @Input() label: string;
  @Input() controller: FormControl<IEmployeeSimple | null>;
  @Input() options: IEmployeeSimple[] = [];

  filteredOptions: IEmployeeSimple[] = [];

  filter(value: string) {
    this.filteredOptions = this.options.filter((option) =>
      option.fullName.toLowerCase().includes(value.toLowerCase()),
    );
  }

  empty(value: string) {
    if (value.trim()) return;

    this.controller.setValue(null);
  }

  displayFn(user: IEmployeeSimple) {
    return user?.fullName;
  }
}
