<div>
  <h2 class="section-title">
    {{ title | translate }}
  </h2>

  <div id="header">
    <div class="header-left">
      <mat-form-field class="calendar-search-field" appearance="fill">
        <mat-icon matPrefix>search</mat-icon>
        <input
          id="search-term"
          type="text"
          placeholder="Search"
          (keyup)="applySearch()"
          [(ngModel)]="search"
          autocomplete="off"
          matInput
        />
        <mat-icon class="close-icon" matSuffix *ngIf="!!search" (click)="clearSearch()"
          >close</mat-icon
        >
      </mat-form-field>

      <div>
        <button mat-outline-button color="primary" (click)="openFilterDialog()">
          <mat-icon>filter_alt</mat-icon>
          <span *ngIf="activeFilterList?.length > 0" class="red-dot"></span>
        </button>
      </div>
    </div>

    <div *ngIf="isAdminView" class="header-right">
      <a routerLink="/admin/employees/create" mat-raised-button color="primary" type="button">
        {{ 'ADMIN.EMPLOYEES.ADD_EMPLOYEE.ADD_USER' | translate }}
      </a>

      <mat-icon
        *ngIf="false"
        matTooltip="{{ 'ADMIN.EMPLOYEES.EXPORT' | translate }}"
        (click)="exportAll()"
        class="icon"
        >ios_share
      </mat-icon>
    </div>
  </div>

  <div *ngIf="activeFilterList?.length > 0">
    <mat-chip-listbox aria-label="filters">
      <span mat-chip *ngFor="let filterItem of activeFilterList">
        {{
          filterItem.termProperty === 'requiredIds'
            ? ('ADMIN.EMPLOYEES.FILTERS.OPERATOR_AND' | translate)
            : ''
        }}
        {{
          filterItem.termProperty === 'optionalIds'
            ? ('ADMIN.EMPLOYEES.FILTERS.OPERATOR_OR' | translate)
            : ''
        }}
        {{ filterItem.term === 'position' ? filterItem.positionName : filterItem.name }}
        <button
          class="clear-btn"
          color="primary"
          mat-icon-button
          (click)="
            clearFilter({
              filterId: filterItem.id,
              term: filterItem.term,
              termProperty: filterItem.termProperty
            })
          "
        >
          <mat-icon>close</mat-icon>
        </button>
      </span>
      <button
        class="clear-all-btn"
        color="primary"
        mat-icon-button
        matTooltip="{{ 'ADMIN.EMPLOYEES.FILTERS.CLEAR_FILTERS' | translate }}"
        (click)="clearFilters()"
      >
        <mat-icon>filter_alt_off</mat-icon>
      </button>
    </mat-chip-listbox>
  </div>

  <mat-spinner *ngIf="!tableConfigSignal()"></mat-spinner>

  <app-table-wrapper
    [hidden]="!tableConfigSignal()"
    [isUrlChangeable]="true"
    matSort
    [sort]="sort"
    [tableConfig]="tableConfigSignal()"
    [(pageable)]="pageable"
    (getData)="onTableGetData()"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'ADMIN.EMPLOYEES.TEAM_MEMBER' | translate }}
      </th>
      <td mat-cell *matCellDef="let employee">
        <a
          class="name-link"
          [routerLink]="[
            isAdminView ? '/admin/employees/update' : '/dreamix/page/employees',
            employee.employeeId
          ]"
        >
          <img
            class="avatar"
            fill
            [src]="employee.avatarUrl || '/assets/img/defaultAvatar.png'"
            [alt]="'avatar_' + employee.employeeId"
          />
          <span>{{ employee.name }}</span>
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="contacts">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'DREAMIX.EMPLOYEES.CONTACTS' | translate }}
      </th>
      <td mat-cell *matCellDef="let employee">
        <div class="contact-actions">
          <button mat-icon-button [matMenuTriggerFor]="phoneMenu">
            <mat-icon>phone</mat-icon>
          </button>
          <mat-menu #phoneMenu="matMenu" xPosition="before">
            <a href="tel:{{ employee.phone }}" mat-menu-item>{{
              'DREAMIX.EMPLOYEES.CALL' | translate
            }}</a>
            <button
              mat-menu-item
              [cdkCopyToClipboard]="employee.phone"
              (click)="openSnackBar(translate.instant('DREAMIX.EMPLOYEES.COPIED').toString())"
            >
              {{ 'DREAMIX.EMPLOYEES.COPY' | translate }}
            </button>
          </mat-menu>
          <button mat-icon-button [matMenuTriggerFor]="mailMenu">
            <mat-icon>mail</mat-icon>
          </button>
          <mat-menu #mailMenu="matMenu" xPosition="before">
            <a href="mailto:{{ employee.email }}" mat-menu-item>{{
              'DREAMIX.EMPLOYEES.WRITE' | translate
            }}</a>
            <button
              mat-menu-item
              [cdkCopyToClipboard]="employee.email"
              (click)="openSnackBar(translate.instant('DREAMIX.EMPLOYEES.COPIED').toString())"
            >
              {{ 'DREAMIX.EMPLOYEES.COPY' | translate }}
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'ADMIN.EMPLOYEES.POSITION' | translate }}
      </th>
      <td mat-cell *matCellDef="let employee">
        {{ employee.position }}
      </td>
    </ng-container>

    <ng-container matColumnDef="skills">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'ADMIN.EMPLOYEES.SKILLS' | translate }}
      </th>
      <td mat-cell *matCellDef="let employee">
        <mat-chip-listbox aria-label="skills">
          <span
            *ngFor="let skill of employee.skills | slice: 0 : 1"
            mat-chip
            matTooltip="{{ employee.skills | restOfList }}"
            matTooltipClass="tooltip-multiline"
          >
            <span class="chip-text">{{ skill.name }}</span>
            <span class="chip-suffix" *ngIf="employee.skills.length > 1"
              >+{{ employee.skills.slice(1).length }}</span
            >
          </span>
        </mat-chip-listbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="certificates">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'ADMIN.EMPLOYEES.CERTIFICATES' | translate }}
      </th>
      <td mat-cell *matCellDef="let employee">
        <mat-chip-listbox aria-label="certificates">
          <span
            *ngFor="let certificate of employee.certificates | slice: 0 : 1"
            mat-chip
            matTooltip="{{ employee.certificates | restOfList }}"
            matTooltipClass="tooltip-multiline"
          >
            <span class="chip-text">{{ certificate.name }}</span>
            <span class="chip-suffix" *ngIf="employee.certificates.length > 1"
              >+{{ employee.certificates.slice(1).length }}</span
            >
          </span>
        </mat-chip-listbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="action" *ngIf="isAdminView">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let employee">
        <mat-icon
          matTooltip="{{ 'ADMIN.EMPLOYEES.MODIFY_EMPLOYEE' | translate }}"
          [routerLink]="['/admin/employees/update', employee['employeeId']]"
          class="icon user-edit"
          >edit
        </mat-icon>
        <mat-icon
          matTooltip="{{ 'ADMIN.EMPLOYEES.DELETE_EMPLOYEE' | translate }}"
          (click)="openDialog(employee['employeeId'])"
          class="icon delete"
          >cancel
        </mat-icon>
      </td>
    </ng-container>
  </app-table-wrapper>
</div>
