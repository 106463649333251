export enum VacationTypeEnum {
  VACATION = 'Vacation',
  SICK_LEAVE = 'Sick leave',
  MATERNITY_PATERNITY = 'Maternity / Paternity',
  //Home office is not a vacation?
  HOME_OFFICE = 'Home office',
  WORK_ON_HOLIDAYS = 'Work on holidays',
  OTHER_PAID_LEAVE = 'Other paid leave',
  LEAVE_OF_ABSENCE = 'Unpaid leave',
}

export enum VacationAbsenceEnum {
  VACATION = 'VACATION',
  SICK_LEAVE = 'SICK_LEAVE ',
  MATERNITY_PATERNITY = 'MATERNITY_PATERNITY',
  WORK_ON_HOLIDAYS = 'WORK_ON_HOLIDAYS',
  OTHER_PAID_LEAVE = 'OTHER_PAID_LEAVE',
  LEAVE_OF_ABSENCE = 'LEAVE_OF_ABSENCE',
}
