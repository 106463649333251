<app-calendar-header
  #calendarHeader
  [handleLoading]="handleLoading"
  [onMonthChange]="getCurrentMonthRequests"
  [isCalendarHome]="isCalendarHome"
/>
<div class="calendar-container">
  <div class="shadow" *ngIf="isLocalLoading">
    <mat-spinner style="z-index: 1" />
  </div>
  <table class="table table-striped">
    <thead>
      <tr class="header-row">
        <div class="action-holder">
          <app-calendar-dialog
            [projectId]="projectId"
            [handleSubmit]="resetCalendar"
            *ngIf="isCalendarHome"
          />
          <div class="new-form" *ngIf="!isCalendarHome">
            <mat-form-field
              appearance="fill"
              class="form-item-full no-padding"
              subscriptSizing="dynamic"
            >
              <mat-label>{{ 'ADMIN.CLIENT_HOURS.PROJECTS' | translate }}</mat-label>
              <input
                type="text"
                matInput
                placeholder="{{ 'ADMIN.CLIENT_HOURS.PROJECTS' | translate }}"
                #filter
                [formControl]="projectsControl"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="onProjectChange($event)"
                [displayWith]="displayFn"
              >
                <mat-option
                  *ngFor="let project of projectList | filter: filter.value : 'name'"
                  [value]="project"
                >
                  {{ project.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <mat-form-field class="calendar-search-field" appearance="fill">
            <mat-icon matPrefix>search</mat-icon>
            <input
              id="search-term"
              type="search"
              placeholder="{{ 'SHARED.SEARCH' | translate }}"
              (ngModelChange)="applyFilter($event)"
              [(ngModel)]="filter"
              autocomplete="off"
              matInput
            />
          </mat-form-field>
        </div>
        <th
          *ngFor="let day of daysDetails; let i = index"
          class="day"
          [class.weekend]="day.isWeekend"
          [class.today]="day.isToday"
          scope="col"
        >
          <div class="day-name">
            {{ day.header }} <span>{{ day.date }}</span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr *ngFor="let emp of currentPageData">
          <td class="employee-name">
            <div class="employee-details">
              <a id="link" [routerLink]="['/dreamix/page/employees', emp.id]">
                {{ emp.fullName }}
              </a>
              <mat-icon
                [matTooltip]="
                  isCalendarHome
                    ? 'Work days: ' + emp.workDays
                    : 'Work days: ' +
                      emp.workDays +
                      '\n' +
                      'Total Working Hours: ' +
                      emp.totalWorkingHours +
                      '\n' +
                      'Month Working Hours: ' +
                      emp.totalMonthWorkingHours
                "
                [matTooltipPosition]="'after'"
                matTooltipClass="multiline-tooltip"
                class="info-icon"
                >info_outlined
              </mat-icon>
              <mat-icon
                class="employee-expand-icon"
                (click)="expandEmployee(emp.id)"
                *ngIf="!isCalendarHome"
                >open_in_full</mat-icon
              >
            </div>
          </td>
          <td
            *ngFor="let day of daysDetails; let i = index"
            [class.holiday]="day.isHoliday"
            [class.weekend]="day.isWeekend"
            [class.today]="day.isToday"
            [class.first-half]="emp.days[day.date]?.firstHalf"
            [class.second-half]="emp.days[day.date]?.secondHalf"
            [ngClass]="[
              emp.days[day.date]
                ? emp.days[day.date].vacationType.toString().toLowerCase() + '-cell'
                : ''
            ]"
          >
            <div
              [class.stripe-green]="getStripeStyles(day, emp, 'STRIPE_GREEN')"
              [style.width.%]="getStripeStyles(day, emp, 'STRIPE_GREEN_WIDTH')"
              [style.left.%]="getStripeStyles(day, emp, 'STRIPE_GREEN_LEFT')"
            ></div>
            <div
              [class.stripe-red]="getStripeStyles(day, emp, 'STRIPE_RED')"
              [style.width.%]="getStripeStyles(day, emp, 'STRIPE_RED_WIDTH')"
              [style.left.%]="getStripeStyles(day, emp, 'STRIPE_RED_LEFT')"
            ></div>
            <mat-icon *ngIf="showVacationIcon(day, emp)">
              {{ vacationIcons[emp.days[day.date].vacationType] }}
            </mat-icon>
            <div *ngIf="day.label"></div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<div>
  <div [hidden]="filter" class="calendar-footer">
    <div>
      <mat-label class="page-size-label">{{ 'SHARED.ITEMS_PER_PAGE' | translate }}</mat-label>
      <mat-form-field appearance="fill" class="page-size-dropdown">
        <mat-select
          [(value)]="pageSize"
          (selectionChange)="onPageSizeChange($event)"
          class="page-size-option"
        >
          <mat-option
            *ngFor="let size of pageSizeOptions"
            [value]="size"
            class="page-size-option"
            >{{ size }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <mat-paginator
      #paginator
      [length]="calendar.length"
      [pageSize]="pageSize"
      hidePageSize
      showFirstLastButtons
      (page)="onPageChange($event)"
    />
  </div>
</div>
