import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardWidgetData } from '../../models/widgets.model';

@Component({
  selector: 'app-widget-dashboard-dialog',
  templateUrl: './widget-dashboard-dialog.component.html',
  styleUrls: ['./widget-dashboard-dialog.component.scss'],
})
export class WidgetDashboardDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public widgetData: DashboardWidgetData[]) {}
}
