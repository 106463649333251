<div class="employee-name" scope="col">
  <button mat-button [matMenuTriggerFor]="menu" class="new-menu-holder">
    {{ 'HOME.CALENDAR.MY_CALENDAR' | translate }}
  </button>
  <mat-menu #menu="matMenu" class="custom-calendar-menu">
    <div class="menu-calendar-content">
      <div class="title-holder">{{ 'HOME.CALENDAR.MY_CALENDAR' | translate }}</div>
      <mat-divider></mat-divider>
      <div *ngFor="let calendar of calendarList">
        <div class="project-name-holder" (click)="$event.stopPropagation()">
          <mat-checkbox
            (change)="removeOrAddCalendar(calendar)"
            [checked]="selectedCalendarIds.includes(calendar.id)"
            >{{
              calendar.projectName ? calendar.projectName : calendar.calendarTitle
            }}</mat-checkbox
          >
          <div class="icons-holder">
            <mat-icon
              class="edit-icon"
              *ngIf="calendar.calendarId && calendar.calendarId !== 1"
              (click)="updatePersonalCalendar(calendar.calendarId)"
              >edit
            </mat-icon>
            <mat-divider
              vertical
              *ngIf="calendar.calendarId && calendar.calendarId !== 1"
            ></mat-divider>
            <mat-icon
              class="delete-icon"
              *ngIf="calendar.calendarId !== 1"
              (click)="deleteCalendar(calendar)"
              >delete
            </mat-icon>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div class="buttons-holder" (click)="$event.stopPropagation()">
        <button mat-stroked-button color="primary" (click)="openCreateCalendarDialog()">
          <mat-icon>calendar_today</mat-icon>
          <span>{{ 'HOME.CALENDAR.CREATE_CALENDAR' | translate }}</span>
        </button>
        <button mat-stroked-button color="primary" (click)="openAddProjectDialog()">
          <mat-icon>add</mat-icon>
          <span>{{ 'HOME.CALENDAR.ADD_PROJECT' | translate }}</span>
        </button>
      </div>
      <button
        mat-raised-button
        type="submit"
        color="primary"
        class="full-width"
        (click)="submitCalendarAndProjects()"
      >
        {{ 'SHARED.SUBMIT' | translate }}
      </button>
    </div>
  </mat-menu>
</div>
