<div class="dialog-container">
  <h1 class="title" mat-dialog-title>{{ data.title }}</h1>
  <mat-dialog-content>{{ data.description }}</mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button class="cancel-button" *ngIf="data.showCancelButton" mat-dialog-close>
      {{ 'SHARED.DIALOGS.DIALOG.CANCEL' | translate }}
    </button>
    <button
      mat-raised-button
      [class]="data.sharedButtonClass"
      [ngClass]="{
        'mat-primary': data.sharedButtonClass === 'warningButton',
        'mat-accent': data.sharedButtonClass === 'successButton',
        'mat-warn': data.sharedButtonClass === 'deleteButton'
      }"
      mat-dialog-close="true"
    >
      {{ data.sharedButtonText }}
    </button>
  </mat-dialog-actions>
</div>
