import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITravelRequest } from '../../models/travel-request.model';

@Component({
  selector: 'app-edit-travel-request-dialog',
  templateUrl: './edit-travel-request-dialog.component.html',
  styleUrls: ['./edit-travel-request-dialog.component.scss'],
})
export class EditTravelRequestDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditTravelRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITravelRequest,
  ) {}

  actionClick(result): void {
    this.dialogRef.close(result);
  }
}
