import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay, tap } from 'rxjs';
import { Floor, FloorCreateRequest, FloorEditRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class OfficeManagementService {
  private urlBase: string = 'api/office-management';
  private floors$: Observable<Floor[]>;

  constructor(private httpClient: HttpClient) {}

  createFloor({ floorName, image }: FloorCreateRequest): Observable<Floor> {
    const formData = new FormData();

    formData.append('floorName', floorName);
    formData.append('image', image);

    return this.httpClient
      .post<Floor>(`${this.urlBase}/floor/create`, formData)
      .pipe(tap(() => (this.floors$ = null)));
  }

  getAllFloors(): Observable<Floor[]> {
    this.floors$ = this.floors$ ?? this.fetchAllFloors().pipe(shareReplay());

    return this.floors$;
  }

  getFloorImage(floorId: number): Observable<Blob> {
    return this.httpClient.get(`${this.urlBase}/floor/${floorId}/image`, {
      responseType: 'blob',
    });
  }

  removeFloor(floorId: number): Observable<any> {
    return this.httpClient
      .delete(`${this.urlBase}/floor/${floorId}`)
      .pipe(tap(() => (this.floors$ = null)));
  }

  updateFloor({ active, id, image, name, primary }: FloorEditRequest): Observable<Floor> {
    const formData = new FormData();

    formData.append('active', String(active));
    formData.append('id', String(id));
    formData.append('name', name);
    formData.append('primary', String(primary));

    if (image) {
      formData.append('image', image);
    }

    return this.httpClient
      .put<Floor>(`${this.urlBase}/floor/update`, formData)
      .pipe(tap(() => (this.floors$ = null)));
  }

  private fetchAllFloors(): Observable<Floor[]> {
    return this.httpClient.get<Floor[]>(`${this.urlBase}/floor/all`);
  }
}
