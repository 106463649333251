import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'restOfList',
})
export class RestOfListPipe implements PipeTransform {
  transform(items: any[]): string {
    return items?.map(({ name }) => `- ${name}`).join('\n');
  }
}
