<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="table-holder">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="created-on">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'USER.MY_REQUESTS.CREATED' | translate }}
      </th>
      <td
        [ngClass]="{ 'invalid-request': element.isFileListEmpty }"
        mat-cell
        *matCellDef="let element"
      >
        <span>{{ element.createdOn }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'USER.REIMBURSEMENT_REQUESTS.TYPE' | translate }}
      </th>
      <td
        [ngClass]="{ 'invalid-request': element.isFileListEmpty }"
        mat-cell
        *matCellDef="let element"
      >
        {{ element.type }}
      </td>
    </ng-container>
    <ng-container matColumnDef="start-date">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'USER.REIMBURSEMENT_REQUESTS.START_DATE' | translate }}
      </th>
      <td
        [ngClass]="{ 'invalid-request': element.isFileListEmpty }"
        mat-cell
        *matCellDef="let element"
      >
        {{ element.fromDate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="end-date">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'USER.REIMBURSEMENT_REQUESTS.END_DATE' | translate }}
      </th>
      <td
        [ngClass]="{ 'invalid-request': element.isFileListEmpty }"
        mat-cell
        *matCellDef="let element"
      >
        {{ element.toDate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="destination">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'USER.REIMBURSEMENT_REQUESTS.DESTINATION' | translate }}
      </th>
      <td
        [ngClass]="{ 'invalid-request': element.isFileListEmpty }"
        mat-cell
        *matCellDef="let element"
      >
        <span *ngIf="element.type === reimbursementTypes.travel"
          >{{ element.fromDestination }} - {{ element.toDestination }}</span
        >
        <span *ngIf="element.type !== reimbursementTypes.travel">N/A</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="update-delete-icons">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        [ngClass]="{ 'invalid-request': element.isFileListEmpty }"
        mat-cell
        *matCellDef="let element"
      >
        <div class="icons-holder">
          <div>
            <mat-icon
              color="primary"
              matTooltip="View uploaded documents"
              (click)="openImageListDialog(element)"
              class="edit-icon"
              >image_search
            </mat-icon>
          </div>

          <div *ngIf="element.isValidDate">
            <mat-icon
              *ngIf="!isEditMode || !(isUpdatedRequestId && isUpdatedRequestId === element.id)"
              color="primary"
              (click)="editReimbursementRequest(element)"
              matTooltip="{{ 'USER.REIMBURSEMENT_REQUESTS.EDIT' | translate }}"
              class="edit-icon"
              >edit
            </mat-icon>
            <mat-icon
              *ngIf="isEditMode && isUpdatedRequestId && isUpdatedRequestId === element.id"
              matTooltip="{{ 'USER.REIMBURSEMENT_REQUESTS.EDIT' | translate }}"
              class="edit-icon"
              >edit
            </mat-icon>
          </div>
          <div>
            <mat-icon *ngIf="!element.isValidDate" class="edit-icon disabled">edit </mat-icon>
          </div>
          <div>
            <mat-icon
              *ngIf="element.isValidDate"
              matTooltip="{{ 'USER.REIMBURSEMENT_REQUESTS.DELETE' | translate }}"
              (click)="openDeleteDialog(element)"
              class="delete-icon"
              >cancel
            </mat-icon>
            <mat-icon class="delete-icon disabled" *ngIf="!element.isValidDate">cancel </mat-icon>
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="10">
        {{ 'USER.REIMBURSEMENT_REQUESTS.NO_DATA' | translate }}
      </td>
    </tr>
  </table>

  <mat-paginator
    *ngIf="!isDisabledPagination"
    class="result-table-paginator"
    [length]="dataLength"
    [pageSizeOptions]="[10, 20, 50]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
