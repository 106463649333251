<app-spinner-overlay *ngIf="isLoading"></app-spinner-overlay>
<mat-dialog-content>
  <h2 mat-dialog-title>
    {{ 'USER.TRAVEL_REQUESTS.UPLOAD_DOCUMENTS' | translate }}
  </h2>

  <div class="line-container">
    <div class="label">{{ 'USER.TRAVEL_REQUESTS.BOARDING_CARDS' | translate }}</div>
    <div class="icons">
      <label for="boardingCards" class="icon save">
        <mat-icon
          color="primary"
          matTooltip="{{ 'USER.TRAVEL_REQUESTS.UPLOAD_BOARDING_CARDS' | translate }}"
          >cloud_upload</mat-icon
        >
      </label>
      <input
        (change)="uploadFile($event, 'boardingCards')"
        type="file"
        accept="image/png,image/jpeg,application/pdf"
        class="file"
        name="boardingCards"
        id="boardingCards"
        multiple
        hidden
      />
      <mat-icon
        color="primary"
        (click)="openUploadedFiles('boardingCards')"
        [class.hidden]="this.uploadForm.controls.boardingCards.value.length === 0"
        matTooltip="{{ 'USER.TRAVEL_REQUESTS.VIEW_UPLOADED_DOCUMENTS' | translate }}"
        >image_search</mat-icon
      >
    </div>
  </div>

  <div class="line-container">
    <div class="label">{{ 'USER.TRAVEL_REQUESTS.OTHER_TRAVEL_EXPENSES' | translate }}</div>
    <div class="icons">
      <label for="additionalDocuments" class="icon save">
        <mat-icon
          color="primary"
          matTooltip="{{ 'USER.TRAVEL_REQUESTS.UPLOAD_OTHER_EXPENSES' | translate }}"
          >cloud_upload</mat-icon
        >
      </label>
      <input
        (change)="uploadFile($event, 'additionalDocuments')"
        type="file"
        accept="image/png,image/jpeg,application/pdf"
        class="file"
        name="additionalDocuments"
        id="additionalDocuments"
        multiple
        hidden
      />
      <mat-icon
        color="primary"
        [class.hidden]="this.uploadForm.controls.additionalDocuments.value.length === 0"
        (click)="openUploadedFiles('additionalDocuments')"
        matTooltip="{{ 'USER.TRAVEL_REQUESTS.VIEW_UPLOADED_DOCUMENTS' | translate }}"
        >image_search</mat-icon
      >
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    class="submit-button"
    mat-raised-button
    color="primary"
    type="submit"
    (click)="submitForm()"
  >
    {{ 'SHARED.SUBMIT' | translate }}
  </button>
  <button mat-button class="cancel-button" mat-dialog-close>
    {{ 'SHARED.DIALOGS.IMAGE_DIALOG.CLOSE' | translate }}
  </button>
  <mat-dialog-actions> </mat-dialog-actions
></mat-dialog-actions>
