export enum VacationSubtypeEnum {
  REGULAR_SICK_LEAVE = 'Regular sick leave',
  DREAMIX_SICK_LEAVE = 'Dreamix sick leave',
  MARRIAGE = 'Marriage',
  BLOOD_DONATION = 'Blood donation',
  RELATIVE_DEATH = 'Relative death',
  COURT_HEARING = 'Court hearing',
  MEMBER_OF_GOVERNMENT_MEETING = 'Member of government meeting',
  TRAINING_FOR_DISASTER_SITUATION = 'Training for disaster situation',
  EMPLOYER_NOTICE_FOR_DISMISSAL = 'Employer notice for dismissal',
  OTHER_PROJECT = 'Other project',
}
