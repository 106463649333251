<div class="time-log-table table-responsive">
  <div class="time-log-hours" *ngIf="data.entries.length">
    <span>Hours Worked: {{ data.totalWorkingHours }}h</span>
    <span class="separator">│</span>
    <span>Working Hours in {{ monthNameGetter + ': ' + data.totalMonthWorkingHours }}h</span>
  </div>
  <table class="table">
    <thead class="thead-light">
      <tr>
        <ng-template [ngIf]="!timeLogDialog">
          <th scope="col" style="width: 10%">#</th>
          <th scope="col" style="width: 50%">
            {{ 'LOG_TIME.INPUT.DESCRIPTION' | translate }}
          </th>
          <th scope="col" style="width: 10%">
            {{ 'LOG_TIME.INPUT.HOURS' | translate }}
          </th>
          <th scope="col" style="width: 20%">
            {{ 'LOG_TIME.TABLE.DATE' | translate }}
          </th>
          <th scope="col" style="width: 10%">
            {{ 'SHARED.ACTIONS' | translate }}
          </th>
        </ng-template>
        <ng-template [ngIf]="timeLogDialog">
          <th scope="col" style="width: 20%">
            {{ 'LOG_TIME.TABLE.DATE' | translate }}
          </th>
          <th scope="col" style="width: 10%">
            {{ 'LOG_TIME.INPUT.HOURS' | translate }}
          </th>
          <th scope="col" style="width: 50%">
            {{ 'LOG_TIME.INPUT.DESCRIPTION' | translate }}
          </th>
          <th scope="col" style="width: 10%">
            {{ 'SHARED.ACTIONS' | translate }}
          </th>
        </ng-template>
      </tr>
    </thead>

    <tbody>
      <div class="table-overlay" *ngIf="isLoading">
        <mat-spinner [diameter]="16"></mat-spinner>
      </div>

      <ng-container *ngFor="let entry of data.entries">
        <tr>
          <ng-template [ngIf]="!timeLogDialog">
            <th scope="row">{{ entry.id }}</th>
            <td>{{ entry.description }}</td>
            <td>{{ entry.hours }}</td>
            <td>{{ entry.date }}</td>
          </ng-template>
          <ng-template [ngIf]="timeLogDialog">
            <td>{{ entry.date }}</td>
            <td>{{ entry.hours }}</td>
            <td>{{ entry.description }}</td>
          </ng-template>
          <td class="actions">
            <button
              mat-icon-button
              aria-label=""
              (click)="toggleEditingEntry(entry.id)"
              [disabled]="isLoading"
            >
              <mat-icon *ngIf="editingEntryId !== entry.id">edit</mat-icon>
              <mat-icon *ngIf="editingEntryId === entry.id">close</mat-icon>
            </button>

            <button
              mat-icon-button
              color="warn"
              aria-label=""
              (click)="deleteEntry(entry.id)"
              [disabled]="isLoading"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
        <tr *ngIf="editingEntryId === entry.id">
          <td colspan="5">
            <app-time-log-input mode="edit" [entry]="entry" />
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
