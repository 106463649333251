import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TimeLogsData } from '../../models/time-log';

@Component({
  selector: 'app-time-log-table-dialog',
  templateUrl: './time-log-table-dialog.component.html',
})
export class TimeLogTableDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: TimeLogsData) {}
}
