<div class="dialog-container" *ngIf="isCreateMode">
  <div>
    <div class="title-holder align-center">
      <h1 class="title" mat-dialog-title>
        {{
          (!isEditMode ? 'HOME.CALENDAR.CREATE_NEW_CALENDAR' : 'HOME.CALENDAR.EDIT_CALENDAR')
            | translate
        }}
      </h1>
      <button mat-mini-fab class="close-button" color="primary" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <form [formGroup]="form" class="inputs-holder">
      <mat-form-field appearance="fill" class="new-input-field">
        <input
          matInput
          type="text"
          placeholder="{{ 'HOME.CALENDAR.TITLE_PLACEHOLDER' | translate }}"
          formControlName="title"
          required
        />
        <mat-error *ngIf="form.controls.title.invalid">{{
          'HOME.CALENDAR.TITLE_REQUIRED' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="new-input-field">
        <input
          matInput
          type="text"
          placeholder="{{ 'HOME.CALENDAR.DESCRIPTION' | translate }}"
          formControlName="description"
        />
      </mat-form-field>
    </form>
  </div>

  <mat-dialog-content>
    <div>
      <h1 class="title no-padding" mat-dialog-title *ngIf="employeeNames.length">
        {{ 'HOME.CALENDAR.ADDED_PEOPLE' | translate }}
      </h1>
      <section *ngFor="let employee of employeeNames; let index = index; let last = last">
        <div class="employee-holder">
          <div>{{ employee }}</div>
          <mat-icon class="delete-icon" (click)="deleteEmployee(index)">delete</mat-icon>
        </div>
        <mat-divider></mat-divider>
      </section>
    </div>

    <div class="action-holder flex-columns">
      <div class="add-people-button-holder">
        <button mat-stroked-button color="primary" (click)="toggleMode()">
          <mat-icon>add</mat-icon>
          <span *ngIf="!employeeNames.length">{{ 'HOME.CALENDAR.ADD_PEOPLE' | translate }}</span>
          <span *ngIf="employeeNames.length">{{
            'HOME.CALENDAR.ADD_MORE_PEOPLE' | translate
          }}</span>
        </button>
      </div>
      <button
        *ngIf="!isEditMode"
        mat-raised-button
        color="primary"
        class="full-width"
        (click)="createCalendar()"
        [disabled]="isButtonDisabled"
      >
        <mat-icon>add</mat-icon>
        <span>{{ 'HOME.CALENDAR.CREATE_CALENDAR' | translate }}</span>
      </button>

      <button
        *ngIf="isEditMode"
        mat-raised-button
        color="primary"
        class="full-width"
        (click)="updateCalendar()"
        [disabled]="isButtonDisabled"
      >
        <mat-icon>add</mat-icon>
        <span>{{ 'HOME.CALENDAR.UPDATE_CALENDAR' | translate }}</span>
      </button>
    </div>
  </mat-dialog-content>
</div>

<div class="dialog-container" *ngIf="!isCreateMode">
  <div class="inputs-holder no-padding">
    <mat-form-field appearance="fill" class="new-input-field">
      <mat-icon matPrefix>search</mat-icon>
      <input
        placeholder="{{ 'HOME.CALENDAR.SEARCH_EMPLOYEE' | translate }}"
        [(ngModel)]="filter"
        autocomplete="off"
        matInput
      />
      <mat-icon
        *ngIf="!this.selectedEmployees.length"
        matSuffix
        class="close-employees"
        (click)="toggleMode()"
        >close
      </mat-icon>
    </mat-form-field>
  </div>
  <mat-dialog-content>
    <div class="flex-columns">
      <div
        *ngFor="
          let employee of list | filter: filter : 'fullName';
          let index = index;
          let last = last
        "
      >
        <mat-checkbox
          (change)="removeOrAddEmployee(employee)"
          [checked]="employeeNames.includes(employee.fullName)"
        >
          {{ employee.fullName }}
        </mat-checkbox>
        <mat-divider *ngIf="!last"></mat-divider>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="add-people-button-holder">
    <button
      *ngIf="this.selectedEmployees.length"
      mat-raised-button
      color="primary"
      class="full-width"
      (click)="toggleMode()"
    >
      <mat-icon>add</mat-icon>
      <span>{{ 'HOME.CALENDAR.ADD_PEOPLE' | translate }}</span>
    </button>
  </mat-dialog-actions>
</div>
