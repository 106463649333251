<form
  [formGroup]="form"
  class="time-log-input"
  (ngSubmit)="submit()"
  #timeLogForm="ngForm"
  *ngIf="!isLoading"
>
  <div class="form-fields">
    <mat-form-field appearance="fill" class="description">
      <mat-label>
        {{ 'LOG_TIME.INPUT.DESCRIPTION' | translate }}
      </mat-label>
      <input matInput type="text" formControlName="description" required />
      <mat-error *ngIf="form.controls.description.invalid && form.controls.description.dirty">{{
        getErrorMessage('description')
      }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="hours">
      <mat-label>
        {{ 'LOG_TIME.INPUT.HOURS' | translate }}
      </mat-label>
      <input
        matInput
        type="number"
        formControlName="hours"
        required
        min="1"
        [max]="maxHours"
        [ngClass]="this.form.controls.hours.value < 1 && 'disable-arrows'"
      />

      <mat-error *ngIf="form.controls.hours.invalid && form.controls.hours.dirty">{{
        getErrorMessage('hours')
      }}</mat-error>
    </mat-form-field>

    <mat-form-field
      (click)="startDate.open()"
      color="accent"
      appearance="fill"
      class="date-picker"
      *ngIf="mode === 'add'"
    >
      <mat-label>
        {{ 'LOG_TIME.INPUT.FROM_DATE' | translate }}
      </mat-label>
      <input
        matInput
        required
        [matDatepicker]="startDate"
        formControlName="startDate"
        [min]="minLoggedDay"
        [value]="startDate"
        [matDatepickerFilter]="isWorkingDay"
        (dateInput)="handleDatePick(true)"
      />
      <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
      <mat-datepicker [dateClass]="getLoggedDateClass" #startDate color="primary"></mat-datepicker>
    </mat-form-field>
    <mat-form-field
      (click)="endDate.open()"
      color="accent"
      appearance="fill"
      class="date-picker"
      *ngIf="mode === 'add'"
    >
      <mat-label>
        {{ 'LOG_TIME.INPUT.TO_DATE' | translate }}
      </mat-label>
      <input
        matInput
        required
        [matDatepicker]="endDate"
        formControlName="endDate"
        [min]="form.controls.startDate.value"
        [max]="maxLoggedDay"
        [value]="endDate"
        [matDatepickerFilter]="isWorkingDay"
        (dateInput)="handleDatePick()"
      />
      <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
      <mat-datepicker #endDate color="primary"></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="hours" *ngIf="mode === 'edit'">
      <mat-label> {{ 'LOG_TIME.TABLE.DATE' | translate }} </mat-label>
      <input matInput type="text" disabled [value]="entry.date.toString()" />
    </mat-form-field>

    <button
      mat-raised-button
      class="add-button"
      type="submit"
      [disabled]="!form.valid"
      color="primary"
    >
      <mat-spinner *ngIf="isWorking" [diameter]="16"></mat-spinner>
      <span *ngIf="!isWorking">
        {{
          mode === 'add'
            ? ('LOG_TIME.INPUT.MODE_ADD' | translate)
            : ('LOG_TIME.INPUT.SAVE' | translate)
        }}
      </span>
    </button>
  </div>
</form>
