<div class="nugget-container" [class.has-footer]="nugget.buttonLink && !nugget.context">
  <div
    class="nugget-content"
    [class.has-column]="nugget.context && nugget.img"
    [class.order-reversed]="nugget.imageOnTheLeft"
  >
    <div *ngIf="nugget.context" class="nugget-context">
      <div [innerHTML]="nugget.context | safeHtml" class="nugget-text"></div>
      <!-- <div [appNuggetContent]="{ title: nugget.title, content: nugget.context }"></div> -->
      <a *ngIf="nugget.buttonLink" mat-raised-button color="primary" [href]="nugget.buttonLink">
        {{ nugget.buttonLabel }}
      </a>
    </div>
    <button *ngIf="nugget.img" class="nugget-image" (click)="openImage(nugget.img)">
      <img [src]="nugget.img | base64ToImage" [alt]="nugget.title" />
    </button>
  </div>
  <div *ngIf="nugget.buttonLink && !nugget.context" class="nugget-footer">
    <a mat-raised-button color="primary" [href]="nugget.buttonLink">
      {{ nugget.buttonLabel }}
    </a>
  </div>
</div>
