import { Injectable } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { IEmployee } from '../models/employee.model';
import { Subscription } from 'rxjs';
import { Role } from '../enums/role.enum';

const ID_LIST_OF_EXCLUSIVE_USERS = [11861]; // kristiyan petrov's id

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private urlBase: string = '/api';
  public user: IEmployee;
  private helper: JwtHelperService = new JwtHelperService();
  readonly defaultAvatarUrl = '/assets/img/defaultAvatar.png';
  public isAuthServiceReady: Boolean = false;
  private isUserLogIn$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isUserLogIn$: Observable<boolean>;
  private authSubscription: Subscription = new Subscription();
  private userAvatar$ = new BehaviorSubject<string>(this.defaultAvatarUrl);

  constructor(
    private authService: SocialAuthService,
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.authService.initState.subscribe((value) => {
      this.isAuthServiceReady = value;
    });
    this.isUserLogIn$ = this.isUserLogIn$$.asObservable();
  }

  public getToken() {
    return this.helper.decodeToken(localStorage.getItem('jwtToken'));
  }

  public getUser(): IEmployee {
    return this.user;
  }

  public setUser(updatedUser: IEmployee): void {
    this.user = updatedUser;
    this.isUserLogIn$$.next(true);
    if (this.user.avatarUrl) {
      this.userAvatar$.next(this.user.avatarUrl);
    }
  }

  public subscribeAuthState(): void {
    this.authSubscription = this.authService.authState.subscribe((user) => {
      if (user) {
        this.httpClient
          .post<IEmployee>(`${this.urlBase}/identify`, user.idToken, {
            observe: 'response',
          })
          .subscribe((response) => this.handleSuccessfulLogin(response));
      }
    });
  }

  public unSubscribeAuthState(): void {
    this.authSubscription.unsubscribe();
  }

  public logIn(): void {
    this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
    this.isUserLogIn$$.next(true);
  }

  refreshAuthToken() {
    throw new Error('Method not implemented.');
  }

  logInWithPassword({ email, password }: { email: string; password: string }) {
    return this.httpClient.post<IEmployee>(`${this.urlBase}/login`, { email, password }, { observe: 'response' });
  }

  logOut(): void {
    if (this.isAuthServiceReady) {
      localStorage.removeItem('jwtToken');
      this.router.navigate(['login']);
      this.authService
        .signOut()
        .then(() => {
          localStorage.removeItem('jwtToken');
          sessionStorage.removeItem('invalidTrips');
          this.router.navigate(['login']);
        })
        .catch((e) => console.error(e));
    }

    this.isUserLogIn$$.next(false);
  }

  isUserLogIn(): boolean {
    return !!localStorage.getItem('jwtToken');
  }

  getUserId(): number {
    return parseInt(this.helper.decodeToken(localStorage.getItem('jwtToken')).sub, 10);
  }

  isUserRoleSimpleUser(): boolean {
    const token = this.getToken();
    return !!token && token.role === Role.USER;
  }

  getUserRole() {
    return this.getToken()?.role;
  }

  isUserRoleUser(): boolean {
    const token = this.getToken();
    return (
      !!token &&
      (token.role === 'USER' ||
        token.role === 'ADMIN' ||
        token.role === 'LEAD' ||
        token.role === 'OPERATIONS_ADMIN' ||
        token.role === 'SUPER_ADMIN' ||
        token.role === 'TC_ADMIN'
      )
    );
  }

  isUserRoleAdmin(): boolean {
    const token = this.getToken();
    return !!token && (token.role === 'ADMIN' || token.role === 'OPERATIONS_ADMIN' || token.role === 'SUPER_ADMIN' || token.role === 'TC_ADMIN');
  }

  isUserRoleLead(): boolean {
    const token = this.getToken();
    return !!token && token.role === 'LEAD';
  }

  isUserRoleDevops(): boolean {
    const token = this.getToken();
    return !!token && token.role === 'DEVOPS';
  }

  isUserRoleOperationsAdmin(): boolean {
    const token = this.getToken();
    return !!token && (token.role === 'OPERATIONS_ADMIN' || token.role === 'SUPER_ADMIN');
  }

  isUserRoleSuperAdmin(): boolean {
    const token = this.getToken();
    return !!token && token.role === 'SUPER_ADMIN';
  }

  isExclusiveUser(): boolean {
    return ID_LIST_OF_EXCLUSIVE_USERS.includes(this.getUserId());
  }

  getAvatarUrlToShow(): Observable<string> {
    return this.userAvatar$.asObservable();
  }

  setAvatarUrlToShow(url: string) {
    this.userAvatar$.next(url);
  }

  async handleSuccessfulLogin(response: HttpResponse<IEmployee>) {
    localStorage.setItem('jwtToken', response.headers.get('authorization'));
    this.isUserLogIn$$.next(true);
    this.user = response.body;
    if (this.user.avatarUrl) {
      this.userAvatar$.next(this.user.avatarUrl);
    }

    if (!this.route.snapshot.queryParams.redirectTo) {
      await this.router.navigate(['/']);
      return;
    }

    const redirectUrl = new URL(this.route.snapshot.queryParams.redirectTo, location.origin);

    await this.router.navigate([redirectUrl.pathname], {
      queryParams: Object.fromEntries(redirectUrl.searchParams.entries()),
    });
  }
}
