import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from 'src/app/shared/dialogs/image-dialog/image-dialog.component';
import { NuggetEvent } from 'src/app/shared/models/nugget-event.model';

@Component({
  selector: 'app-weekly-nugget',
  templateUrl: './weekly-nugget.component.html',
  styleUrls: ['./weekly-nugget.component.scss'],
})
export class WeeklyNuggetComponent {
  @Input() nugget: NuggetEvent;

  constructor(private dialog: MatDialog) {}

  openImage(src: string) {
    this.dialog.open(ImageDialogComponent, { data: { url: src, base64: true } });
  }
}
