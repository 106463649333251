<mat-dialog-content>
  <h5>{{ data.title }}</h5>
  <image-cropper
    [imageChangedEvent]="data.imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="1"
    [resizeToWidth]="176"
    [transform]="transform"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
  >
  </image-cropper>

  <h5>{{ 'SHARED.DIALOGS.IMAGE_CROP_DIALOG.PREVIEW' | translate }}</h5>
  <img [src]="croppedImage" />
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button class="cancel-button" mat-dialog-close>
    {{ 'SHARED.DIALOGS.IMAGE_CROP_DIALOG.CLOSE' | translate }}
  </button>
  <button mat-raised-button color="primary" mat-dialog-close="{{ getCroppedImage() }}">
    {{ data.sharedButtonText }}
  </button>
</mat-dialog-actions>
