import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

/**
 * `FileUploadButtonComponent` is a reusable Angular component for file uploading.
 *
 * Inputs:
 * - `control`: An `AbstractControl` linked to the selected file.
 * - `buttonText`: A `string` for the initial button text, updates with the selected file name.
 *
 * Output:
 * - `fileSelected`: Emits the selected `File` object if valid, or `null` if invalid.
 *
 * Restrictions:
 * - Only accepts files with the following extensions: 'jpg', 'jpeg', 'png', 'bmp', 'tiff', 'gif', 'pdf'.
 */

@Component({
  selector: 'app-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss'],
})
export class FileUploadButtonComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  _acceptedTypes: Object;
  @Input() control: FormControl | AbstractControl;
  @Input() set acceptedTypes(acceptedTypes: Object) {
    this._acceptedTypes = acceptedTypes;
  }
  get acceptedTypes(): Object {
    return this._acceptedTypes;
  }
  @Output() fileSelected: EventEmitter<File> = new EventEmitter();
  acceptAttr: Object;

  defaultAcceptedTypes = {
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    doc: 'application/msword',
    odt: 'application/vnd.oasis.opendocument.text',
    csv: 'text/csv',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    pdf: 'application/pdf',
  };

  ngOnInit() {
    this.acceptAttr = this._acceptedTypes
      ? Object.values(this._acceptedTypes).concat()
      : Object.values(this.defaultAcceptedTypes).concat();
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    this.fileInput.nativeElement.value = '';
    if (file && Object.values(this.acceptedTypes).includes(file.type)) {
      this.fileSelected.emit(file);
    } else {
      this.fileSelected.emit(null);
    }
  }

  deleteFile() {
    this.fileSelected.emit(null);
    this.fileInput.nativeElement.value = '';
  }
}
