import { NativeDateAdapter } from '@angular/material/core';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  constructor(@Inject(LOCALE_ID) public locale: string) {
    super(locale);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}
