import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DialogComponent, ImageListDialogComponent } from '../../dialogs';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_BUTTONS, REIMBURSEMENT_TYPES } from '../../constants';
import { IReimbursement } from '../../models/reimbursement.model';

@Component({
  selector: 'app-reimbursement-requests-table',
  templateUrl: './reimbursement-requests-table.component.html',
  styleUrls: ['./reimbursement-requests-table.component.scss'],
})
export class ReimbursementRequestsTableComponent {
  isDisabledPagination: boolean = false;
  isUpdatedRequestId;
  userId: number;
  readonly reimbursementTypes = REIMBURSEMENT_TYPES;
  displayedColumns: string[] = [
    'created-on',
    'type',
    'start-date',
    'end-date',
    'destination',
    'update-delete-icons',
  ];

  dataLength: number;
  readonly buttons = DIALOG_BUTTONS;

  dataSource = new MatTableDataSource<IReimbursement>();

  @Output() requestToEdit: EventEmitter<IReimbursement> = new EventEmitter();
  @Output() requestToDelete: EventEmitter<IReimbursement> = new EventEmitter();
  @Input() isEditMode: boolean = false;
  @Input() isLoading: boolean = false;

  @Input()
  set data(data: IReimbursement[]) {
    const copyData = [...data];
    copyData.forEach((item) => {
      if (
        (item.type === this.reimbursementTypes.travel &&
          !item.accommodationReimbursementDocumentName &&
          !item.transportReimbursementDocumentName) ||
        (item.type !== this.reimbursementTypes.travel && !item.reimbursementDocumentNames.length)
      ) {
        item['isFileListEmpty'] = true;
      }
    });

    this.dataSource = new MatTableDataSource<IReimbursement>(copyData);
    this.dataLength = copyData.length;
    this.dataSource.paginator = this.paginator;
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
  ) {}

  public openDeleteDialog(element: IReimbursement): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DELETE_CONFIRMATION'),
          description:
            this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DELETE_CONFIRMATION_DESCRIPTION') +
            `${element.type} and ID: ${element.id}?`,
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DELETE'),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.requestToDelete.emit(element);
        }
      });
  }

  openImageListDialog(element: any): void {
    const fileList = [...element.reimbursementDocumentNames];

    if (
      element.accommodationReimbursementDocumentName &&
      !fileList.includes(element.accommodationReimbursementDocumentName)
    ) {
      fileList.push(element.accommodationReimbursementDocumentName);
    }
    if (
      element.transportReimbursementDocumentName &&
      !fileList.includes(element.transportReimbursementDocumentName)
    ) {
      fileList.push(element.transportReimbursementDocumentName);
    }

    this.dialog.open(ImageListDialogComponent, {
      data: {
        requestId: element.id,
        fileList: fileList,
        readOnly: true,
        disableFilePreview: true,
        url: `api/reimbursement/${element.employeeId}/${element.id}/`,
      },
    });
  }

  public editReimbursementRequest(element: IReimbursement): void {
    this.isUpdatedRequestId = element.id;
    this.requestToEdit.emit(element);
  }
}
