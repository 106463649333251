import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import {
  AddEntryPayload,
  TimeLogEntry,
  TimeLogsData,
  TimeLogsResponse,
  UpdateEntryPayload,
} from '../models/time-log';

@Injectable({
  providedIn: 'root',
})
export class TimeLogService {
  private urlBase: string = 'api/time-logging';

  constructor(private httpClient: HttpClient) {}

  async addEntry(payload: AddEntryPayload): Promise<any> {
    return await lastValueFrom(this.httpClient.post(`${this.urlBase}/entries`, payload));
  }

  async updateEntry(
    currentUserId: number,
    entryId: number,
    payload: UpdateEntryPayload,
  ): Promise<any> {
    return await lastValueFrom(
      this.httpClient.put(`${this.urlBase}/${currentUserId}/${entryId}`, payload),
    );
  }

  async deleteEntry(entryId: number): Promise<any> {
    return await lastValueFrom(this.httpClient.delete(`${this.urlBase}/${entryId}`));
  }

  // @ts-ignore
  async loadEntries(
    projectId: number,
    employeeId: number,
    start: string,
    end: string,
  ): Promise<TimeLogsData> {
    const result = await lastValueFrom(
      this.httpClient.get<TimeLogsResponse>(
        `${this.urlBase}/projects/${projectId}/employee/${employeeId}?fromDate=${start}&toDate=${end}`,
      ),
    );
    const { loggedTimesPerProjectPerEmployee, ...rest } = result;

    // TODO: BUG
    return {
      ...rest,
      entries: loggedTimesPerProjectPerEmployee.map((entry: TimeLogEntry) => ({
        startDate: new Date(entry.startDate),
        endDate: new Date(entry.startDate),
        ...entry,
      })),
    };
  }

  submitReport(projectId, payload: FormData): Observable<any> {
    return this.httpClient.post(`${this.urlBase}/projects/${projectId}/validate-report`, payload);
  }
}
