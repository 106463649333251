import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ImageListDialogComponent } from 'src/app/shared/dialogs';
import { IBusinessTripParticipantDocumentsDto } from 'src/app/shared/models/business-trip-participants.model';
import { ITravelRequest, ITravelRequestPartial } from 'src/app/shared/models/travel-request.model';
import { AlertService, AlertType, LoginService } from 'src/app/shared/services';
import { TravelRequestService } from 'src/app/shared/services/travel-request.service';

@Component({
  selector: 'app-upload-travel-documents-dialog',
  templateUrl: './upload-travel-documents-dialog.component.html',
  styleUrls: ['./upload-travel-documents-dialog.component.scss'],
})
export class UploadTravelDocumentsDialogComponent implements OnInit, OnDestroy {
  uploadForm: FormGroup;
  userId: number;
  private destroy$ = new Subject<void>();
  isLoading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<UploadTravelDocumentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITravelRequestPartial,
    private travelRequestsService: TravelRequestService,
    private loginService: LoginService,
    private dialog: MatDialog,
    private alertService: AlertService,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.buildForm();
    this.userId = this.loginService.getUserId();
    this.getRequestDetails();
  }

  buildForm() {
    this.uploadForm = new FormGroup({
      boardingCards: new FormControl([]),
      additionalDocuments: new FormControl([]),
    });
  }

  getRequestDetails() {
    this.travelRequestsService
      .getTravelRequestById(this.userId, this.data.businessTripId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ITravelRequest) => {
        let participant: IBusinessTripParticipantDocumentsDto =
          data.businessTripParticipantDocumentsDto.find((dto) => dto.participantId === this.userId);
        this.isLoading = false;
        this.uploadForm.controls.boardingCards.setValue(participant.boardingCards);
        this.uploadForm.controls.additionalDocuments.setValue(participant.additionalDocuments);
      });
  }

  uploadFile(event, controlName) {
    if (controlName === 'boardingCards') {
      this.uploadForm.controls.boardingCards.setValue([
        ...this.uploadForm.controls.boardingCards.value,
        ...event.target.files,
      ]);
    } else {
      this.uploadForm.controls.additionalDocuments.setValue([
        ...this.uploadForm.controls.additionalDocuments.value,
        ...event.target.files,
      ]);
    }
  }

  openUploadedFiles(controlName) {
    const files = this.uploadForm.get(`${controlName}`).value;
    const fileList = [];
    const fileContent = [];
    files.forEach((file) => {
      if (typeof file != 'string') {
        fileList.push(file.name);
        fileContent.push(file);
      } else {
        fileList.push(file);
      }
    });
    const dialogRef = this.dialog.open(ImageListDialogComponent, {
      data: {
        requestId: null,
        fileList,
        readOnly: false,
        fileContent,
        url: `api/business-trip/${this.loginService.user.id}/${this.data.businessTripId}/${this.loginService.user.id}/`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.travelRequestsService
          .deleteFile(
            this.loginService.user.id,
            this.data.businessTripId,
            this.loginService.user.id,
            result,
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {});
      }
      const filesLeftAfterDeletion = files.filter((file) =>
        file.name ? file.name !== result : file !== result,
      );
      this.uploadForm.get(`${controlName}`).setValue([...filesLeftAfterDeletion]);
    });
  }

  submitForm() {
    this.isLoading = true;
    const formData: FormData = new FormData();
    const payload: any = this.uploadForm.value;
    for (let file of payload.boardingCards) {
      if (typeof file != 'string') {
        formData.append(
          `boardingCards`,
          new Blob([file], {
            type: 'application/octet-stream',
          }),
          file.name,
        );
      }
    }

    for (let file of payload.additionalDocuments) {
      if (typeof file != 'string') {
        formData.append(
          `additionalDocuments`,
          new Blob([file], {
            type: 'application/octet-stream',
          }),
          file.name,
        );
      }
    }
    this.travelRequestsService
      .uploadtravelRequestDocuments(this.data.businessTripId, this.userId, formData)
      .subscribe({
        next: () => {
          this.isLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.alertService.showAlert(error.statusText, AlertType.error);
        },
        complete: () => {
          this.dialogRef.close();
          this.alertService.showAlert(
            this.translate.instant('USER.TRAVEL_REQUESTS.SUCCESSFUL_SUBMIT'),
            AlertType.success,
          );
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
