import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { DIALOG_BUTTONS } from 'src/app/shared/constants';
import { DialogComponent } from 'src/app/shared/dialogs';
import { CalendarAddProjectDialogComponent } from 'src/app/shared/dialogs/calendar-add-project-dialog/calendar-add-project-dialog.component';
import { CalendarCreateDialogComponent } from 'src/app/shared/dialogs/calendar-create-dialog/calendar-create-dialog.component';
import { IEmployee } from 'src/app/shared/models';
import { PersonalCalendarRequest } from 'src/app/shared/models/personal-calendar.model';
import { AlertService, AlertType, LoginService } from 'src/app/shared/services';
import { PersonalCalendarService } from 'src/app/shared/services/personal-calendar.service';

@Component({
  selector: 'app-calendar-dialog',
  templateUrl: './calendar-dialog.component.html',
  styleUrls: ['./calendar-dialog.component.scss'],
})
export class CalendarDialogComponent implements OnInit, OnDestroy {
  @Input()
  public projectId: number;

  @Input()
  public handleSubmit: () => void;

  public user: IEmployee;
  private destroy$ = new Subject<void>();

  calendarList: PersonalCalendarRequest[] = [];
  selectedCalendars: PersonalCalendarRequest[] = [];
  selectedCalendarIds: number[] = [];

  readonly buttons = DIALOG_BUTTONS;

  constructor(
    public loginService: LoginService,
    private alertService: AlertService,
    private personalCalendarService: PersonalCalendarService,
    public translate: TranslateService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.user = this.loginService.getUser();

    this.personalCalendarService
      .getAllCalendars(this.user.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: PersonalCalendarRequest[]) => {
        this.personalCalendarService.setListOfCalendars(data);
      });

    this.personalCalendarService
      .getListOfCalendars()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: PersonalCalendarRequest[]) => {
        this.calendarList = data;
        this.sortCalendarList();
        this.selectedCalendars = [...this.calendarList.filter((item) => item.checked === true)];
        this.selectedCalendarIds = this.selectedCalendars.map((calendar) => calendar.id);
      });
  }

  sortCalendarList() {
    this.calendarList.sort((a, b) => {
      if (a.checked && !b.checked) {
        return -1;
      } else if (!a.checked && b.checked) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  removeOrAddCalendar(calendar: PersonalCalendarRequest) {
    const index = this.selectedCalendars.findIndex((item) => item.id === calendar.id);
    if (index >= 0) {
      this.selectedCalendars.splice(index, 1);
    } else {
      this.selectedCalendars.push(calendar);
    }
    this.selectedCalendarIds = this.selectedCalendars.map((calendar) => calendar.id);
  }

  updatePersonalCalendar(calendarId: number) {
    this.personalCalendarService
      .getPersonalCalendarById(calendarId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.dialog.open(CalendarCreateDialogComponent, {
            data: {
              userId: this.user.id,
              isEditMode: true,
              calendar: data,
            },
          });
        },
        (error) => {
          this.alertService.showAlert(error.error, AlertType.error);
        },
        () => {},
      );
  }

  deleteCalendar(calendar: PersonalCalendarRequest) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DELETE_CONFIRMATION'),
          description:
            this.translate.instant('ADMIN.PAGES.DELETE_CONFIRMATION_DESCRIPTION') +
            `${calendar.projectName ? calendar.projectName : calendar.calendarTitle}?`,
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DELETE'),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const deletePersonalOrProject = calendar.calendarId
            ? this.personalCalendarService.deletePersonalCalendar(calendar.calendarId, this.user.id)
            : this.personalCalendarService.deleteProjectFromCalendar(
                calendar.projectId,
                this.user.id,
              );

          deletePersonalOrProject.pipe(takeUntil(this.destroy$)).subscribe(
            (data) => {
              this.personalCalendarService.setListOfCalendars(data);
              this.alertService.showAlert(
                this.translate.instant('ADMIN.LICENSES.SUCCESSFULLY_REMOVED'),
                AlertType.success,
              );
            },
            (error) => {
              this.selectedCalendarIds.includes(calendar.id)
                ? this.alertService.showAlert(error.error, AlertType.error)
                : this.alertService.showAlert(
                    this.translate.instant('HOME.CALENDAR.UNSUCCESSFUL_DELETION'),
                    AlertType.error,
                  );
            },
          );
        }
      });
  }

  submitCalendarAndProjects() {
    this.selectedCalendars.forEach((calendar) => (calendar.checked = true));
    this.calendarList.forEach((item) => {
      item.checked = this.selectedCalendarIds.includes(item.id);
    });
    this.sortCalendarList();

    this.personalCalendarService
      .submitCalendarsAndProjects(this.user.id, this.calendarList)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.alertService.showAlert(
            this.translate.instant('HOME.CALENDAR.SUCCESSFULLY_SUBMITTED'),
            AlertType.success,
          );
        },
        (error) => this.alertService.showAlert(error.error.error, AlertType.error),
        () => {
          this.handleSubmit();
        },
      );
  }

  openAddProjectDialog() {
    this.dialog.open(CalendarAddProjectDialogComponent, {
      data: {
        userId: this.user.id,
      },
    });
  }

  openCreateCalendarDialog() {
    this.dialog.open(CalendarCreateDialogComponent, {
      data: {
        userId: this.user.id,
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
