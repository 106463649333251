<button
  mat-raised-button
  color="primary"
  class="upload-btn"
  type="button"
  (click)="fileInput.click()"
  [disabled]="control.value"
>
  <mat-icon>cloud_upload</mat-icon>
  <span>{{ 'USER.REIMBURSEMENT_REQUESTS.UPLOAD_FILE' | translate }}</span>
</button>
<div class="file-names" *ngIf="control.value">
  <span>{{ control.value.name }}</span>
  <mat-icon (click)="deleteFile()">cancel</mat-icon>
</div>
<mat-error class="file-names error" *ngIf="!control.value">{{
  'FILE_UPLOAD_BUTTON.ERROR_REQUIRED' | translate
}}</mat-error>
<input type="file" #fileInput hidden (change)="onFileSelected($event)" [accept]="acceptAttr" />
