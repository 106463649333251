<mat-form-field class="full-width">
  <mat-label>{{ label }}{{ required ? '*' : '' }}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="controller"
    [matAutocomplete]="auto"
    (input)="filter($event.target.value)"
    (focus)="filter($event.target.value)"
    (blur)="empty($event.target.value)"
    [required]="required"
  />
  <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
      {{ option.fullName }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="controller.invalid && (controller.dirty || controller.touched)">
    <ng-container *ngIf="controller.errors?.required">{{
      'SHARED.VALIDATION.REQUIRED' | translate
    }}</ng-container>
  </mat-error>
</mat-form-field>
