<mat-form-field
  appearance="fill"
  class="full-width"
  color="primary"
  floatLabel="auto"
>
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row *ngFor="let item of selected" (removed)="remove(item)">
      {{ item[nameProperty] }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input
    #input
    [formControl]="control"
    [matAutocomplete]="auto"
    [matChipInputFor]="chipGrid"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    [placeholder]="placeholder"
    (blur)="onTouched()"
    (focusout)="clearInputValue()"
    (matChipInputTokenEnd)="clearInputValue()"
    />
  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption
    (optionSelected)="onSelect($event)"
  >
    <ng-container *ngFor="let item of filtered$ | async">
      <mat-option [value]="item" *ngIf="!isInList(item)">
        {{ item[nameProperty] }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
