export enum TimeLogLoader {
  page = 'page',
  projects = 'projects',
  entries = 'entries',
  add = 'add',
  edit = 'edit',
  delete = 'delete',
  // TODO: remove
  submit = 'submit',
}

export interface TimeLogsResponse {
  loggedTimesPerProjectPerEmployee: Array<TimeLogEntry>;
  totalMonthWorkingHours: number;
  totalWorkingHours: number;
}
export interface TimeLogsData extends Omit<TimeLogsResponse, 'loggedTimesPerProjectPerEmployee'> {
  entries: Array<TimeLogEntry>;
}
export interface TimeLogEntry {
  date: Date;
  id: number;
  startDate: Date;
  endDate: Date;
  description: string;
  hours: number;
}

export type TimeLogEntryMode = 'add' | 'edit' | 'delete';

export interface TimeLogEntryAction {
  mode: TimeLogEntryMode;
  event: 'start' | 'success' | 'error';
  payload?: any;
}

export interface NewTimeLogEntry {
  date: string;
  description: string;
  hours: number;
}

export interface UpdateEntryPayload {
  description: string;
  hours: number;
}

export interface AddEntryPayload {
  employeeId: number;
  projectId: number;
  dates: Array<NewTimeLogEntry>;
}
