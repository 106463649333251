import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginService } from '../services/login.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private helper: JwtHelperService = new JwtHelperService();

  constructor(public loginService: LoginService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem('jwtToken');

    if (idToken != null && this.helper.isTokenExpired(idToken)) {
      this.loginService.logOut();
    }

    if (!idToken) {
      return next.handle(req);
    }

    const cloned = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${idToken}`),
    });

    return next.handle(cloned).pipe(
      catchError((error) => {
        if (error.status === 401) {
          this.loginService.logOut();
          location.reload();
        }
        return throwError(() => error);
      }),
    );
  }
}
