<div class="file-list-container">
  <h1 mat-dialog-title>File List</h1>
  <mat-dialog-content>
    <div class="file-list" *ngFor="let filename of data.fileList; let i = index">
      <div class="file-name">{{ filename }}</div>
      <div class="icon-holder">
        <button mat-icon-button (click)="openPicDialog(filename)">
          <mat-icon class="icon save">image_search</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteFile(filename, i)" *ngIf="!data.readOnly">
          <mat-icon matTooltip="{{ 'SHARED.DELETE' | translate }}" class="icon delete"
            >cancel
          </mat-icon>
        </button>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="button-holder">
    <button mat-button class="cancel-button" mat-dialog-close>
      {{ 'SHARED.DIALOGS.IMAGE_DIALOG.CLOSE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
