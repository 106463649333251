import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { DIALOG_BUTTONS } from '../../constants';
import { DialogComponent } from '../dialog/dialog.component';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-image-list-dialog',
  templateUrl: './image-list-dialog.component.html',
  styleUrls: ['./image-list-dialog.component.scss'],
})
export class ImageListDialogComponent {
  readonly buttons = DIALOG_BUTTONS;
  @Output() deleteFileEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      requestId: string;
      userId?: number;
      fileList: string[];
      readOnly: boolean;
      fileContent?: File[];
      url?: string;
    },
    public dialogRef: MatDialogRef<ImageListDialogComponent>,
    public translate: TranslateService,
    private dialog: MatDialog,
    private httpClient: HttpClient,
  ) {}

  private baseLoadFile(fileName: string): Observable<string> {
    const url = this.data.url ? this.data.url : `api/requests/load_file/${this.data.userId}/`;

    const httpOptions = {
      responseType: 'arraybuffer' as 'json',
    };

    return this.httpClient.get<string>(`${url}${fileName}`, httpOptions);
  }

  //TODO: Extract this login into shared function and pass inject onOpen function
  public openPicDialog(filename: string): void {
    if (!(this.data.fileContent && this.data.fileContent.length)) {
      if (filename.endsWith('pdf')) {
        this.baseLoadFile(filename).subscribe((data) => {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        });
      } else {
        this.dialog.open(ImageDialogComponent, {
          data: {
            url:
              (this.data.url ? this.data.url : `/api/requests/load_file/${this.data.userId}/`) +
              filename,
          },
        });
      }
    } else {
      const file = this.data.fileContent.find((file) => filename === file.name);
      if (file) {
        const fileToBlob = new Blob([file], {
          type: file.type,
        });
        const fileURL = URL.createObjectURL(fileToBlob);
        window.open(fileURL);
      }
    }
  }

  public deleteFile(fileName: string, index: number): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('ADMIN.PAGES.DELETE_CONFIRMATION').toString(),
          description:
            this.translate.instant('ADMIN.PAGES.DELETE_CONFIRMATION_DESCRIPTION').toString() +
            `${fileName}?`,
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('ADMIN.PAGES.DELETE_BUTTON').toString(),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.deleteFileEvent.emit({ fileName, index });
          this.dialogRef.close({ fileName, index });
        }
      });
  }
}

export type DeleteFileEvent = {
  fileName: string;
  index: number;
};
