<button
  *ngIf="!editorIsActive"
  mat-raised-button
  color="basic"
  matTooltip="To avoid exceeding usage limit, please only open the content editor if you intend to edit the content. Note that each view of the rich text editor counts towards usage limit."
  (click)="openEditor()"
>
  <mat-icon>edit</mat-icon>
  Open Editor
</button>

<editor
  *ngIf="editorIsActive"
  [(ngModel)]="value"
  (ngModelChange)="updateChanges()"
  class="editor"
  apiKey="qckis5hvchubi0174udnek8r5ola6mxk2znvdzw0gl4ta652"
  [init]="editorConfig"
  >{{ 'SHARED.COMPONENTS.EDITOR.MY_EDITOR' | translate }}</editor
>
