import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { formatDate, ViewportScroller } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { DIALOG_BUTTONS } from 'src/app/shared/constants';
import { DeleteFileEvent, ImageListDialogComponent } from 'src/app/shared/dialogs/image-list-dialog/image-list-dialog.component';
import { ComponentModeEnum } from 'src/app/shared/enums/component-mode.enum';
import { DestinationStatus } from 'src/app/shared/enums/destination-status.enum';
import { EmployeePartial, IEmployee } from 'src/app/shared/models';
import { IBusinessTripParticipantDocumentsDto } from 'src/app/shared/models/business-trip-participants.model';
import { ITravelRequest, ITravelRequestPartial } from 'src/app/shared/models/travel-request.model';

import { AlertService, AlertType, EmployeeService, LoginService } from 'src/app/shared/services';
import { TravelRequestService } from 'src/app/shared/services/travel-request.service';

//TODO: Implement separation of concerns
@Component({
  selector: 'app-travel-requests',
  templateUrl: './travel-requests.component.html',
  styleUrls: ['./travel-requests.component.scss'],
})
export class TravelRequestsComponent implements OnInit, OnDestroy {
  _requestToEdit;
  _requestToEdit$ = new BehaviorSubject<ITravelRequestPartial>(null);
  isRequestDeletedSub!: Subscription;

  @Input() set requestToEdit(requestToEdit: ITravelRequestPartial) {
    this._requestToEdit$.next(requestToEdit);
    this._requestToEdit = requestToEdit;
  }

  get requestToEdit(): ITravelRequestPartial {
    return this._requestToEdit;
  }

  @Output() action: EventEmitter<string> = new EventEmitter();

  @Output() triggerTableRerender: EventEmitter<null> = new EventEmitter();
  requesterId: number;
  businessTripId: number;
  componentModeEnum = ComponentModeEnum;
  isLoading: boolean = true;
  employees: EmployeePartial[] = [];
  selectedEmployees: EmployeePartial[] = [];
  filteredEmployees: Observable<EmployeePartial[]>;
  travelForm: FormGroup;
  isRoundTrip: boolean = false;
  isAdmin: boolean = this.loginService.isUserRoleAdmin();
  buttonLoadingState: boolean = false;
  public _mode: ComponentModeEnum = ComponentModeEnum.ADD;
  get mode() {
    return this._mode;
  }

  set mode(mode) {
    this._mode = mode;
    this.handleModeChange();
  }

  handleModeChange() {
    if (this.isAdmin) return;
    this.inView = this.mode === this.componentModeEnum.VIEW;
    this.travelForm.controls.purpose[this.inView ? 'disable' : 'enable']();
    this.participantsCtrl[this.inView ? 'disable' : 'enable']();
  }

  public inView: boolean =
    !this.loginService.isUserRoleAdmin() && this.mode !== this.componentModeEnum.ADD;

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  participantsCtrl = this.CustomControl();
  removedDestination = [];
  @ViewChild('employeeInput') employeeInput: ElementRef<HTMLInputElement>;
  @ViewChild('formDirective') private formDirective: NgForm;

  //participants  table
  displayedColumns: string[] = ['participant', 'boarding-cards', 'other-expenses'];
  @ViewChild(MatTable) table: MatTable<IEmployee>;
  readonly buttons = DIALOG_BUTTONS;

  private destroy$ = new Subject<void>();

  constructor(
    public translate: TranslateService,
    public loginService: LoginService,
    private employeeService: EmployeeService,
    private travelRequestService: TravelRequestService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private viewportScroller: ViewportScroller,
  ) {}

  public ngOnInit(): void {
    this.isLoading = true;
    const getAllEmployees$ = this.employeeService.getPartialEmployees();
    combineLatest([getAllEmployees$, this._requestToEdit$])
      .pipe(filter(([employees, _requestToEdit]) => !!employees))
      .subscribe(([employees, requestToEdit]) => {
        this.employees = employees;
        this.isLoading = false;
        if (requestToEdit) {
          this.renderRequestToEdit(requestToEdit);
        }
      });

    this.buildForm();
    this.filteredEmployees = this.participantsCtrl.valueChanges.pipe(
      map((employee: any) => this.filterEmployeesByName(employee)),
    );

    this.isRequestDeletedSub = this.travelRequestService
      .getIsTravelRequestDeleted()
      .subscribe((value) => {
        if (value) {
          this.resetForm();
          this.travelRequestService.setIsTravelRequestDeleted(false);
        }
      });
  }

  private CustomControl(value: unknown = '', required = true) {
    return new FormControl(
      { value, disabled: this.inView },
      required ? Validators.required : undefined,
    );
  }

  private getBaseDestinationFormGroup() {
    return new FormGroup({
      departureDate: this.CustomControl(),
      returnDate: this.CustomControl(null),
      fromCity: this.CustomControl(),
      fromCountry: this.CustomControl(),
      toCity: this.CustomControl(),
      toCountry: this.CustomControl(),
      status: this.CustomControl(DestinationStatus.NEW),
    });
  }

  buildForm() {
    this.travelForm = new FormGroup({
      destination: new FormArray([this.getBaseDestinationFormGroup()]),
      roundTrip: this.CustomControl(false),
      purpose: this.CustomControl(),
      participantDocuments: new FormArray([], Validators.required),
      status: this.CustomControl('Created', false),
      abroadTrip: this.CustomControl(true, true),
    });
  }

  get status() {
    return this.travelForm.controls.destination;
  }

  get destinationFormArray() {
    return this.travelForm.controls.destination as FormArray;
  }

  get participantsFormArray() {
    return this.travelForm.controls.participantDocuments as FormArray;
  }

  private filterEmployeesByName(value: string): EmployeePartial[] {
    this.sortParticipants(this.employees);
    if (!value) {
      return this.employees;
    }

    if (typeof value !== 'string') {
      return this.employees.filter((employee) => employee !== value);
    }
    const filterValue = value.toLowerCase();
    return this.employees.filter((employee) =>
      `${employee.firstNameEn} ${employee.lastNameEn}`.toLowerCase().includes(filterValue),
    );
  }

  public removeEmployee(id: number): void {
    this.selectedEmployees = this.selectedEmployees.filter((employee) => employee.id !== id);
    const index = this.participantsFormArray.value.findIndex(
      (element) => id === element.participant.id,
    );
    if (index !== -1) {
      this.participantsFormArray.removeAt(index);
    }
    this.table.renderRows();
  }

  public sortParticipants(list: EmployeePartial[]) {
    list.sort((a, b) =>
      `${a.firstNameEn} ${a.lastNameEn}`.localeCompare(`${b.firstNameEn} ${b.lastNameEn}`),
    );
  }

  public onAddEmployee(event: MatChipInputEvent): void {
    const input = event.input;
    if (input) {
      input.value = '';
    }
  }

  handleCompleteClick(ev, el) {
    ev.preventDefault();
    el.checked = !el.checked;
    this.travelForm.controls.status.setValue(el.checked ? 'Completed' : 'Created');
  }

  public clearParticipantInputValue() {
    this.employeeInput.nativeElement.value = '';
  }

  public selectEmployee(event: MatAutocompleteSelectedEvent): void {
    this.selectedEmployees.push(event.option.value);
    this.table.renderRows();
    this.employeeInput.nativeElement.value = '';
    this.participantsFormArray.push(
      new FormGroup({
        participant: new FormControl(event.option.value),
        boardingCards: new FormControl([]),
        additionalDocuments: new FormControl([]),
      }),
    );
    this.participantsCtrl.setValue('');
  }

  public isEmployeeInProject(employee: EmployeePartial): boolean {
    if (this.selectedEmployees) {
      return this.selectedEmployees.some((e) => e.id === employee.id);
    }
    return false;
  }

  addDestination() {
    this.destinationFormArray.push(this.getBaseDestinationFormGroup());
  }

  changeStateOfAddButton(value: boolean) {
    this.travelForm.controls.roundTrip.setValue(value);
    this.isRoundTrip = value;
  }

  deleteDestinationRow(index: number) {
    const destination = this.destinationFormArray.controls[index];
    if (this.mode !== ComponentModeEnum.ADD && destination.value.id) {
      this.removedDestination.push({
        ...destination.getRawValue(),
        status: DestinationStatus.DELETED,
      });
    }
    this.destinationFormArray.removeAt(index);
  }

  uploadFile(event, employeeId, controlName) {
    const index = this.participantsFormArray.value.findIndex(
      (element) => employeeId === element.participant.id,
    );
    const control = this.participantsFormArray.at(index);

    control.setValue({
      ...control.value,
      [controlName]: [...control.value[controlName], ...event.target.files],
    });
  }

  openUploadedFiles(id, controlName) {
    const index = this.participantsFormArray.value.findIndex(
      (element) => id === element.participant.id,
    );
    const control = this.participantsFormArray.at(index);
    let files = this.participantsFormArray.controls[index].value[controlName];

    const fileList = [];
    const fileContent = [];
    files.forEach((file) => {
      if (typeof file != 'string') {
        fileList.push(file.name);
        fileContent.push(file);
      } else {
        fileList.push(file);
      }
    });

    const dialogRef = this.dialog.open(ImageListDialogComponent, {
      data: {
        requestId: null,
        fileList,
        readOnly: this.inView,
        fileContent,
        url: `api/business-trip/${this.loginService.user.id}/${this.businessTripId}/${id}/`,
      },
    });
    dialogRef.afterClosed().subscribe((event:DeleteFileEvent) => {
      if (event.fileName) {
        if (this.mode != this.componentModeEnum.ADD) {
          this.travelRequestService
            .deleteFile(this.loginService.user.id, this.businessTripId, id, event.fileName)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {});
        }
        files.splice(event.index, 1)
        control.setValue({
          ...control.value,
          [controlName]: [...files],
        });
      }
    });
  }

  renderRequestToEdit(travelRequestPartial: ITravelRequestPartial) {
    const userId = travelRequestPartial.requesterId;
    const businessTripId = travelRequestPartial.businessTripId;
    if (businessTripId === this.businessTripId) return;
    this.isLoading = true;
    this.travelRequestService
      .getTravelRequestById(userId, businessTripId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ITravelRequest) => {
        this.isLoading = false;
        this.resetForm();
        this.viewportScroller.scrollToPosition([0, 0]);
        const travelRequest = data;
        this.mode =
          travelRequest.status === 'Created' ? ComponentModeEnum.EDIT : ComponentModeEnum.VIEW;

        this.destinationFormArray.removeAt(0);
        this.travelForm.reset();
        this.selectedEmployees = [];
        this.requesterId = userId;
        this.businessTripId = travelRequest.id;
        this.isRoundTrip = travelRequest.roundTrip;
        this.travelForm.controls.roundTrip.setValue(travelRequest.roundTrip);
        this.travelForm.controls.purpose.setValue(travelRequest.purpose);
        this.travelForm.controls.status.setValue(travelRequest.status);
        this.travelForm.controls.abroadTrip.setValue(travelRequest.abroadTrip);
        this.travelForm.controls.destination.enable();
        if (travelRequest.destination.length >= 1) {
          for (let i = 0; i < travelRequest.destination.length; i++) {
            this.destinationFormArray.push(
              new FormGroup({
                departureDate: this.CustomControl(
                  new Date(travelRequest.destination[i].departureDate),
                ),
                returnDate: this.CustomControl(new Date(travelRequest.destination[i].returnDate)),
                fromCity: this.CustomControl(travelRequest.destination[i].fromCity),
                fromCountry: this.CustomControl(travelRequest.destination[i].fromCountry),
                toCity: this.CustomControl(travelRequest.destination[i].toCity),
                toCountry: this.CustomControl(travelRequest.destination[i].toCountry),
                status: this.CustomControl(DestinationStatus.OLD, false),
                id: this.CustomControl(new FormControl(travelRequest.destination[i].id), false),
              }),
            );
          }
        }
        travelRequest.businessTripParticipantDocumentsDto.forEach(
          (element: IBusinessTripParticipantDocumentsDto) => {
            const employee = this.employees.find(
              (employee) => employee.id === element.participantId,
            );

            this.selectedEmployees.push(employee);
            this.participantsFormArray.push(
              new FormGroup({
                participant: this.CustomControl(employee, false),
                boardingCards: this.CustomControl(element.boardingCards, false),
                additionalDocuments: this.CustomControl(element.additionalDocuments, false),
              }),
            );
          },
        );
      });
  }

  cancelUpdate = (incomingTripId = this.businessTripId) => {
    if (incomingTripId === this.businessTripId) {
      this.action.emit('cancel');
      this.resetForm();
    }
  };

  private resetForm() {
    this.formDirective.resetForm();
    this.mode = ComponentModeEnum.ADD;
    this.travelForm.reset({ roundTrip: false });
    this.participantsFormArray.clear();
    this.selectedEmployees = [];
    this.isRoundTrip = false;
    this.viewportScroller.scrollToPosition([0, 0]);
    this.businessTripId = undefined;
    this.destinationFormArray.clear();
    this.addDestination();
    this.travelForm.markAsPristine();
    this.travelForm.markAsUntouched();
  }

  onSubmit() {
    const formData: FormData = new FormData();
    const payload: any = this.travelForm.getRawValue();
    const appendDestinations = (destinations, startIndex = 0) => {
      for (let i = 0; i < destinations.length; i++) {
        const formIndex = startIndex + i;
        if (this.mode !== ComponentModeEnum.ADD) {
          if (destinations[i].status != DestinationStatus.NEW) {
            formData.append(`destination[${formIndex}].id`, destinations[i].id.value);
          }
          formData.append(`destination[${formIndex}].status`, destinations[i].status);
        }

        formData.append(
          `destination[${formIndex}].departureDate`,
          formatDate(destinations[i].departureDate, 'yyyy-MM-dd', 'en-US'),
        );
        formData.append(
          `destination[${i}].returnDate`,
          formatDate(destinations[i].returnDate, 'yyyy-MM-dd', 'en-US'),
        );
        formData.append(`destination[${formIndex}].fromCity`, destinations[i].fromCity);
        formData.append(`destination[${formIndex}].toCity`, destinations[i].toCity);
        formData.append(`destination[${formIndex}].fromCountry`, destinations[i].fromCountry);
        formData.append(`destination[${formIndex}].toCountry`, destinations[i].toCountry);
      }
    };
    appendDestinations(payload.destination);
    appendDestinations(this.removedDestination, payload.destination.length);
    for (let i = 0; i < payload.participantDocuments.length; i++) {
      formData.append(
        `businessTripParticipantDocumentsDto[${i}].participantId`,
        payload.participantDocuments[i].participant.id,
      );
      for (let file of payload.participantDocuments[i].boardingCards) {
        if (typeof file != 'string') {
          formData.append(
            `businessTripParticipantDocumentsDto[${i}].boardingCards`,
            new Blob([file], {
              type: 'application/octet-stream',
            }),
            file.name,
          );
        }
      }
      for (let file of payload.participantDocuments[i].additionalDocuments) {
        if (typeof file != 'string') {
          formData.append(
            `businessTripParticipantDocumentsDto[${i}].additionalDocuments`,
            new Blob([file], {
              type: 'application/octet-stream',
            }),
            file.name,
          );
        }
      }
    }

    formData.append('roundTrip', payload.roundTrip);
    formData.append('purpose', payload.purpose);
    formData.append('status', payload.status === 'Completed' ? payload.status : 'Created');
    formData.append('abroadTrip', payload.abroadTrip);
    if (this.mode === ComponentModeEnum.ADD) {
      this.buttonLoadingState = true;
      this.travelRequestService
        .createTravelRequest(formData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.buttonLoadingState = false;
            this.action.emit('add');
            this.resetForm();
          },
          (error: HttpErrorResponse) => {
            this.buttonLoadingState = false;
            this.alertService.showAlert(error.statusText, AlertType.error);
          },
          () => {
            this.alertService.showAlert(
              this.translate.instant('USER.TRAVEL_REQUESTS.SUCCESSFUL_SUBMIT').toString(),
              AlertType.success,
            );
          },
        );
    } else {
      this.travelRequestService
        .updateTravelRequest(this.requesterId, this.businessTripId, formData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.alertService.showAlert(
              this.translate.instant('USER.TRAVEL_REQUESTS.SUCCESSFUL_UPDATE').toString(),
              AlertType.success,
            );
            this.triggerTableRerender.emit(null);
            this.action.emit();
            this.resetForm();
            this.mode = ComponentModeEnum.ADD;
          },
          (error: HttpErrorResponse) => this.alertService.showServerError(error),
        );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.isRequestDeletedSub) {
      this.isRequestDeletedSub.unsubscribe();
    }
  }
}
