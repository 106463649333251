<div class="dialog-container">
  <div class="title-holder align-center">
    <h1 mat-dialog-title>{{ 'HOME.CALENDAR.CHOOSE_PROJECT' | translate }}</h1>
    <button mat-mini-fab class="close-button" color="primary" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="flex-columns">
    <mat-form-field appearance="fill" class="new-input-field">
      <mat-icon matPrefix>search</mat-icon>
      <input
        placeholder="{{ 'HOME.CALENDAR.SEARCH_PROJECT' | translate }}"
        [(ngModel)]="filter"
        autocomplete="off"
        matInput
      />
    </mat-form-field>
  </div>

  <mat-dialog-content>
    <div class="projects-holder" *ngFor="let project of allProjects | filter: filter : 'name'">
      <mat-checkbox
        (change)="removeOrAddProject(project)"
        [checked]="projectNames.includes(project.name)"
        >{{ project.name }}</mat-checkbox
      >
      <mat-divider></mat-divider>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-raised-button
      color="primary"
      class="full-width"
      type="submit"
      (click)="addProject()"
    >
      <mat-icon>add</mat-icon>
      <span>{{ 'HOME.CALENDAR.ADD_PROJECT' | translate }}</span>
    </button>
  </mat-dialog-actions>
</div>
